// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import PanZoomControl from '../../../KMN-utils-browser/pan-zoom-control.js';
import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import { RenderControl } from '../../../KMN-varstack-browser/components/webgl/render-control.js';

const glsl = x => x[0]; // makes the glsl-literal VS Code extention work
function getVertexShader() {
  return glsl`
    in vec2 vertexPosition;
    out vec2 textureCoord;
    uniform vec2 scale;
    uniform vec2 position;
    void main(void) {
      vec2 pos = vertexPosition.xy;
      textureCoord = (0.5 + 0.5 * pos) / scale + position;
      gl_Position = vec4(pos, 0.0, 1.0);
    }`
}

  // The shader that calculates the pixel values for the filled triangles
function getFragmentShader() {
  return glsl`precision highp float;
  precision highp float;
  precision highp int;
  precision highp sampler2DArray;

  in vec2 textureCoord;
  out vec4 fragColor;
  uniform int startOffset;
  uniform sampler2DArray soundTextures;

  float noise (float st) {
    return fract(sin(dot(st,
                         123.9898))*
              93758.5453123);
 }

 float line(vec2 p, vec2 a, vec2 b)
  {
    vec2 pa = p - a;
    vec2 ba = b - a;
    float h = clamp(dot(pa, ba) / dot(ba, ba), 0.0, 1.0);
      return length(pa - ba * h);
  }

  void main(void) {
    int texIx = int(floor(textureCoord.x * float(bufferCount)));
    float px = 1.0 / float(bufferWidth) / float(bufferCount);
    float py = 1.0 / float(bufferHeight);

    float newY = mod(textureCoord.y, py);
    float xPos = mod(textureCoord.x, 1.0 / float(bufferCount)) * float(bufferCount);

    vec2 sampleValue = texelFetch(soundTextures,
      ivec3(int(floor(xPos * float(bufferWidth))),
            int(floor(textureCoord.y * float(bufferHeight))),
            (texIx + startOffset) % bufferCount),0).rg;
    // vec2 sampleValue = texture(soundTextures,
    //   vec3(xPos,
    //        1.0 - (textureCoord.y - newY) - 0.5 * py,
    //        (texIx + startOffset) % bufferCount)).rg * 0.8;

    float y = 1.0 - 2.0 * (newY / py);
    vec2 dist = abs(vec2(y)-sampleValue);
    // fragColor = vec4((1.0-smoothstep(0.02,0.3,dist)), 0.0, 1.0);
    fragColor = vec4(
        (1.0-smoothstep(0.07,0.08,dist)) * (0.2 + 0.8 * smoothstep(0.0,0.01,abs(sampleValue.x)+abs(sampleValue.y)))
        , pow(max(0.0,length(sampleValue.xy)-1.0),0.5)*0.25, 1.0);
    // fragColor *= vec4(0.5+0.5*sampleValue,0.0,1.0);
  }
  `
}

const cssStr = /*css*/`
.sound-analyzer {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}
`;

// Last buffer
function getFragmentShaderLast() {
  return glsl`precision highp float;
  precision highp float;
  precision highp int;
  precision highp sampler2DArray;

  in vec2 textureCoord;
  out vec4 fragColor;
  uniform int startOffset;
  uniform sampler2DArray soundTextures;

  float noise (float st) {
    return fract(sin(dot(st,
                         123.9898))*
              93758.5453123);
 }

 float line(vec2 p, vec2 a, vec2 b)
  {
    vec2 pa = p - a;
    vec2 ba = b - a;
    float h = clamp(dot(pa, ba) / dot(ba, ba), 0.0, 1.0);
      return length(pa - ba * h);
  }

  void main(void) {
    // Get the last buffer
    int texIx = (startOffset + bufferCount - 1) % bufferCount;

    // Calculate pixel sizes in texture samples
    float px = 1.0 / float(bufferWidth);
    float py = 1.0 / float(bufferHeight);

    float newY = mod(textureCoord.y, py);

    vec2 sampleValue = texture(soundTextures,
      vec3(textureCoord.x,
           1.0 - (textureCoord.y - newY) + 0.5 * py,
           texIx)).rg;

    float y = 1.0 - 2.0 * (mod(textureCoord.y, py) / py);
    vec2 dist = abs(vec2(y)-sampleValue);
    fragColor = vec4((1.0-smoothstep(0.07,0.17,dist)) * smoothstep(0.0,0.01,abs(sampleValue.x)+abs(sampleValue.y)), 0.0, 1.0);
  }
  `
}

// Last buffer Last line is still double, 1st not shown so y still off, probably because the videocard applies its own half pixel
function getFragmentShaderFourier() {
  return glsl`precision highp float;
  precision highp float;
  precision highp int;
  precision highp sampler2DArray;

  in vec2 textureCoord;
  out vec4 fragColor;
  uniform int startOffset;
  uniform sampler2DArray soundTextures;

  const float pi2 = 6.283185307179586;
  const float pwr = 3.0;

  float noise (float st) {
    return fract(sin(dot(st,
                         123.9898))*
              93758.5453123);
 }

 float line(vec2 p, vec2 a, vec2 b)
  {
    vec2 pa = p - a;
    vec2 ba = b - a;
    float h = clamp(dot(pa, ba) / dot(ba, ba), 0.0, 1.0);
      return length(pa - ba * h);
  }

  void main(void) {
    // Get the last buffer
    int texIx = (startOffset + bufferCount - 1) % bufferCount;

    // Calculate pixel sizes in texture samples
    float px = 1.0 / float(bufferWidth);
    float py = 1.0 / float(bufferHeight);

    float newY = mod(textureCoord.y, py);

    float frequency = (pow(pwr, textureCoord.x) - 1.0) * (4500.0 / (pwr - 1.0)) + 20.0;
    float totalTime = float(bufferWidth) / float(sampleRate);
    float trackY = 1.0 - (textureCoord.y - newY) + 0.5 * py;

    // Only frequencies that fit exactly in the space
    frequency -= mod(frequency, 1.0 / totalTime);

    float phase = 0.0;
    float step = ((totalTime * frequency) * pi2) / float(bufferWidth);

    float y = 1.0 - 2.0 * (mod(textureCoord.y, py) / py);

    vec2 tracer = vec2(0.0);
    if (y > 0.0) {
      // Fourier
      for (int ix = 0; ix < bufferWidth; ix++) {
        vec2 sampleValue = texture(soundTextures,
          vec3((float(ix) / float(bufferWidth)) + 0.5 * px,
               trackY,
               texIx)).rg;
        float val = sampleValue.x + sampleValue.y;
        tracer += val * vec2(cos(phase), sin(phase));
        phase += step;
      }
      tracer *= totalTime;
    } else {
      // weighted value
      // vec2 speed = vec2(0.0);
      // vec2 hair = vec2(0.0);
      // for (int ix = 0; ix < 50; ix++) {
      //   vec2 sampleValue = texture(soundTextures,
      //     vec3((float(ix) / float(bufferWidth)) + 0.5 * px,
      //          trackY,
      //          texIx)).rg;

      //   // Simulate oscilating hair
      //   vec2 delta = (hair - sampleValue);
      //   // Resists movement based on weight
      //   speed += delta * (frequency / 60.000);

      //   // Wants to return to center
      //   hair = hair * 0.9 + 0.01 * speed;

      //   tracer = max(tracer, abs(hair));
      // }
      // tracer *= 0.0001;
    }

    // tracer = pow(tracer, vec2(1.0/2.2));
    fragColor = vec4(abs(tracer), 0.0, 1.0).rbga;
  }
  `
}

class SoundAnalyzer extends PanelBase {
  constructor(options) {
    super({}, options);
    this.fullScreen = false;
    this.updateCanvasBound = this.updateCanvas.bind(this);
    this.rc = RenderControl.geInstance();
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement)

    this.canvas = this.options.canvas || this.parentElement.$el({tag:'canvas', cls:'sound-analyzer'});
    this.parentElement.ondblclick = () => {
      this.webglSynth && (this.webglSynth.stopOutput = !this.webglSynth.stopOutput);
    };

    // this.parentElement.onclick = () => {
    //   this.parentElement.toggleClass('fullscreen');
    //   this.fullScreen = !this.fullScreen;
    // }
    // TODO make standard control for this (this is a copy from track-timeline)
    this.control = new PanZoomControl(this.parentElement, {
      minYScale: 1.0,
      maxYScale: 1000.0,
      minXScale: 1.0,
      maxXScale: 1000.0
    });
    this.control.xOffset = 0;
    this.control.xScale= 6.72749994932561;
    this.control.yOffset= 0;
    this.control.yScale= 41.14477778925097;
  }

  updateCanvas() {
    let gl = this.gl;
    let shader = this.shader;

    if (gl && shader && this.parentElement) {

      let {w, h, dpr} = this.rc.updateCanvasSize();

      let rect = this.parentElement.getBoundingClientRect();
      if (rect.width && rect.height) {
        if (!this.fullScreen) {
          // Tell WebGL how to convert from clip space to pixels
          gl.viewport(rect.x * dpr, h - (rect.y + rect.height) * dpr, rect.width * dpr, rect.height * dpr);
          w = rect.width * dpr;
          h = rect.height * dpr;
        } else {
          gl.viewport(0, 0, w, h);
        }

        // Tell WebGL how to convert from clip space to pixels
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.useProgram(shader);

        shader.u.startOffset.set(0); // this.webglSynth.processCount);s
        shader.u.scale?.set(this.control.xScale, this.control.yScale);
        shader.u.position?.set(this.control.xOffset, this.control.yOffset);

        shader.a.vertexPosition.en();
        shader.a.vertexPosition.set(this.vertexBuffer, 2 /* elements per vertex */);

        gl.activeTexture(gl.TEXTURE0);
        // shader.u.bufferHeight.set(this.webglSynth.bufferHeight);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, this.webglSynth.sampleTextures[0].texture);
        // gl.bindTexture(gl.TEXTURE_2D_ARRAY, this.webglSynth.volumeTexture);

        gl.uniform1i(shader.u.soundTextures, 0);

        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

        shader.a.vertexPosition.dis();
      }
    }
    window.requestAnimationFrame(this.updateCanvasBound);
  }

  setSynth(webglSynth) {
    this.webglSynth = webglSynth;
    let gl = this.gl = webglSynth.gl;

    // Create two triangles to form a square that covers the whole canvas
    const basic2triangles = [
      -1, -1,
       1, -1,
      -1,  1,
       1,  1,
       1, -1 ];
    this.vertexBuffer = gl.updateOrCreateFloatArray(0, basic2triangles);
    this.shader = gl.getShaderProgram(
      getVertexShader(),
      this.webglSynth.getDefaultDefines()+
      getFragmentShader(),
      2);

    window.requestAnimationFrame(this.updateCanvasBound);
    // this.webglSynth.onBufferFinished = () => this.updateCanvas();
    // this.invalidate();
  }

  loadSource(sampleData, channelCount) {
    this.sampleData = sampleData;
    this.channelCount = channelCount;
    // this.invalidate()
  }
}
SoundAnalyzer.getTabName = function() { return 'GRAPHS' }

export default SoundAnalyzer;