// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/
import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import { Types } from '../../../KMN-varstack.js/varstack.js';
import dataModel from '../../data/dataModel.js';
import { TableBuilder }  from '../../../KMN-varstack-browser/components/table-builder.js';
import { addCSS, kmnClassName } from '../../../KMN-varstack-browser/utils/html-utils.js';
import { createInputs } from '../../../KMN-varstack-browser/components/input-builder.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --midiMapFilterHeight: 26px;
  --midiMapFilterWidth: 160px;
}
.${kmnClassName}.midiMapHeader {
  height: var(--midiMapFilterHeight);
}
.${kmnClassName}.midiMapFilterContent {
  background: var(--tableBackground);
  left: -1px;
  width: var(--midiMapFilterWidth);
  height: var(--midiMapFilterHeight);
  bottom: 0;
}
.${kmnClassName}.midiMapTableDiv {
  height: calc(100% - var(--midiMapFilterHeight));
  bottom: 0;
}
`;
const defaultOptions = {
};

// TODO: Name is to generic, consider local or scoped types?
Types.addRecord('FilterRecord', {
  projectOnly: 'Bool'
});

class MidiMap extends PanelBase {
  constructor(options) {
    super(defaultOptions, options);
    this.midiMap = dataModel.midiMap;

    this.filterInfo = new Types.FilterRecord();
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement);
    addCSS('midi-map', cssStr);

    this.detailAndFilter        = this.parentElement.$el({    cls: "midiMapHeader"       });
      this.instrumentFilter       = this.detailAndFilter.$el({  cls: "instrumentFilter"       });
        this.instrumentFilterEl     = this.instrumentFilter.$el({ cls: "midiMapFilterContent"});
    this.midiMapTableDiv     = this.parentElement.$el({    cls: "midiMapTableDiv"     });

    createInputs(this.instrumentFilterEl, this.filterInfo);

    this.instrumentTableEl = new TableBuilder(
      this.midiMapTableDiv,
      // @ts-ignore
      this.midiMap,
      {
        fieldNames: ['nr','name','instrument','preset'],
        inlineEdit: true,
        sortOnHeaderClick: true,
        showFilterEdits: true,
        onRowClick: (rec, ix) => {
          dataModel.currentMidiMap.index.$v = ix;
        }
      });

    this.filterInfo.projectOnly.$addEvent((x) => {
      // if (x.$v) {
      //   this.instrumentTableEl.setFilter('nr', dataModel.getInstrumentsUsed());
      // } else {
      //   this.instrumentTableEl.clearFilter();
      // }
    });
  }
}

MidiMap.getTabName = function() { return 'MIDI-MAP' }

export default MidiMap;
