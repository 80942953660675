// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/

// Import the HTML extentions so everyone can use them
import '../KMN-varstack-browser/utils/html-utils.js';

import dataModel from './data/dataModel.js';

import MidiInput from './midi-input.js';

import TabController from '../KMN-utils-browser/components/tab-controller.js';
import SoundAnalyzer from './panels/synth/sound-analyzer.js';
import { ArangementPanel } from './panels/midi/arrangement.js';
import MidiMap from './panels/midi/midi-map.js';
import BitMidiSearch from './panels/midi/bitmidi-search.js';
import TablePanel from '../KMN-varstack-browser/panels/table-panel.js';
// import AudioRecorder from './tab/audio-recorder.js';

// Import all components for UI
import { MenuOpenCloseElement } from '../KMN-varstack-browser/components/webgl/menu-open-close.js';
import { KnobElement } from '../KMN-varstack-browser/components/webgl/knobs.js';
import { UnlockLockElement } from '../KMN-varstack-browser/components/webgl/unlock-lock.js';
import { XYPadElement } from '../KMN-varstack-browser/components/webgl/xy-pad.js';
import { VerticalLevelElement } from '../KMN-varstack-browser/components/webgl/indicators.js';
import { PlayPauseElement } from '../KMN-varstack-browser/components/webgl/play-pause.js';
import { PlayNextElement } from '../KMN-varstack-browser/components/webgl/play-next.js';
import { PlayPreviousElement } from '../KMN-varstack-browser/components/webgl/play-previous.js';
import { PlayForwardElement } from '../KMN-varstack-browser/components/webgl/play-forward.js';
import { PlayReverseElement } from '../KMN-varstack-browser/components/webgl/play-reverse.js';
import { ProgressBarElement } from '../KMN-varstack-browser/components/webgl/progress-bar.js';
import { StarRatingElement } from '../KMN-varstack-browser/components/webgl/star-rating.js';
import { SoloChannelElement } from '../KMN-varstack-browser/components/webgl/solo-channel.js';
import { MasterOutputElement } from '../KMN-varstack-browser/components/webgl/master-output.js';

import { CreateInputBinding } from '../KMN-varstack-browser/utils/input-binding.js';
import SubTabs from '../KMN-utils-browser/components/sub-tabs.js';
import { RenderControl } from '../KMN-varstack-browser/components/webgl/render-control.js';
import UIShaderDetail from './panels/ui-shader-detail.js';
import { Scope } from '../KMN-gl-synth-browser/components/webgl/scope.js';
import { createDefaultDB } from '../KMN-utils-browser/indexed-db.js';
import { ShaderSynthController } from './shader-synth-controller.js';
import getWebGLContext from '../KMN-utils.js/webglutils.js';
import { InstrumentsPanel } from './panels/synth/instruments-panel.js';
import { ShaderDetails } from './panels/synth/code-controls-tabs.js';
import { Splitter } from '../KMN-utils-browser/splitter.js';
import UIShaderTestArea from './panels/ui-shader-test-area.js';
import { addCSS, kmnClassName } from '../KMN-varstack-browser/utils/html-utils.js';
import { GLTextBinding, LetterTest } from '../KMN-varstack-browser/components/webgl/render-text.js';
import { setDefualtTextBinding } from '../KMN-varstack-browser/utils/inner-text-binding.js';
const cssStr = /*css*/`
:root {
  --headerHeight: 42px;
  --tracksHeight: min(32%, 520px);

  --borderWidth: 2px;

  --tableBackground: rgb(20, 20, 20);
  --tableHeaderBackground: rgb(48, 48, 48);
  --tableHeaderColor: rgb(164, 164, 164);
  --borderWidth2: var(--borderWidth) * 2;
  --headerBackground: rgb(0,0,0);
  --headerColor: rgb(192, 192, 192);
  --activeColor: rgb(48,48,80);
  --activeHoverColor: rgb(62,62,192);
  --subBorderWidth: 1px;
  --subHeaderBackground: rgb(76,76,76);
  --subHeaderColor: rgb(192,192,192);
  --borderColor: rgb(0,0,0);
  --backgroundColor: rgb(32,32,32);
  --scrollBarThumb: rgb(64,64,64);
}

.${kmnClassName}.headerArea {
  background: var(--headerBackground);
  font-size: 31px;
  padding-left: 8px;
  font-family: Lobster, Tahoma, Arial;
  line-height: 39px;
  color: var(--headerColor);
  top: 0px;
  width: calc(100% - 8px);
  height: var(--headerHeight);
}
.${kmnClassName}.contentArea {
  background: var(--backgroundColor);
  border: var(--borderWidth) solid var(--borderColor);
  left: 50%;
  top: var(--headerHeight);
  width: calc(50% - var(--borderWidth2));
  height: calc(100% - var(--headerHeight) - var(--tracksHeight)  - var(--borderWidth2));
}
.${kmnClassName}.settingsArea {
  background: var(--backgroundColor);
  border: var(--borderWidth) solid var(--borderColor);
  top: var(--headerHeight);
  width: calc(50% - var(--borderWidth2));
  height: calc(100% - var(--headerHeight) - var(--tracksHeight)  - var(--borderWidth2));
}
.${kmnClassName}.codeArea {
  z-index: 3;
  background: var(--codeBackground);
  border: var(--borderWidth) solid var(--borderColor);
  border-right: none;
  border-bottom: none;
  outline: var(--borderWidth) solid var(--borderColor);
  top: var(--headerHeight);
  left: 50%;
  width: calc(50% - var(--borderWidth));
  height: calc(100% - var(--headerHeight) - var(--tracksHeight) - var(--borderWidth));
}
.${kmnClassName}.tracksArea {
  background: var(--backgroundColor);
  border: var(--borderWidth) solid var(--borderColor);
  bottom: 0;
  width: calc(100% - var(--borderWidth2));
  height: calc(var(--tracksHeight) - var(--borderWidth2));
}
.headerScope {
  right: 0;
  width: 480px;
}
`;

// setTableBuilderClass(TableBuilder);

class Main {
  constructor (options) {
    this.options = options || {}

    this.dataModel = dataModel;
    this.idb = createDefaultDB('ShaderSynthDB3');
    // setDefualtTextBinding(GLTextBinding);
    setTimeout(() => { this.initializeDOM(); }, 0);
    setTimeout(() => { this.initializeSynth(); }, 0);
  }

  initializeDOM() {
    addCSS('main',cssStr);
    this.elements = {
      header: document.body.$el({ cls: 'headerArea' }),
      settings: document.body.$el({ cls: 'settingsArea' }),
      tracks: document.body.$el({ cls: 'tracksArea' }),
      content: document.body.$el({ cls: 'contentArea' })
    };

    this.elements.header.$setTextNode('ShaderSynth');

    // disableRetina(true);
    this.canvas = document.body.$el({tag:'canvas', cls:'overlayCanvas'});
    this.gl = getWebGLContext(this.canvas, { alpha: true, desynchronized: true });

    this.rightController = new TabController({ hideTabs:true });

    this.bottomController = new TabController({ hideTabs:true });

    this.leftController = new TabController({
      onSelect: (tabInfo) => {
        this.rightController.setSelectedTab(tabInfo.tabName);
        this.bottomController.setSelectedTab(tabInfo.tabName);
      }
    });

    this.rightController.addTabFromDiv('WELCOME', 'welcomeDiv');
    this.midiMapDetailTabs = this.rightController.addTab(SubTabs, { tabName: 'MIDI-MAP' });
    this.instrumentDetailTabs = this.rightController.addTab(SubTabs, { tabName: 'INSTRUMENTS' });
    this.rightController.addTab(UIShaderDetail);

    this.midiMapDetailTabs.tabController.addTab(TablePanel, {
      tabName: 'SELECT-INSTRUMENT',
      searchTable: dataModel.instrumentTable,
      fieldNames: ['name','shader'],
      onRowClick: (rec,ix) => dataModel.currentMidiMap.cursor.$v.instrument.$v = rec.name.$v
    });


    // this.codeEditor.initializeDOM(this.elements.code);
    this.instrumentDetailTabs.tabController.addTab(ShaderDetails);
    // this.contentController.addTab(ControlSearch);

    this.trackPanel = this.bottomController.addTab(ArangementPanel, { canvas: this.canvas });
    this.uiShaderTestArea = this.bottomController.addTab(UIShaderTestArea, {
      tabName: 'UI-SHADERS',
      canvas: this.canvas
    });
    // this.trackEditor = new TrackEditor();
    // this.trackEditor.initializeDOM(this.elements.tracks, this.canvas);

    this.bitmidiSearch = this.leftController.addTab(BitMidiSearch );

    this.leftController.addTab(MidiMap       );
    this.leftController.addTab(InstrumentsPanel);

    dataModel.midiEditor = this.trackPanel.trackEditor;

    this.bitmidiSearch.onLoadMidi = this.trackPanel.trackEditor.loadMidi.bind(this.trackPanel.trackEditor);

    this.leftController.addTab(TablePanel, {
      tabName: 'CONTROLS',
      searchTable: dataModel.controlTable,
      searchField: 'controlNames.name',
      fieldNames:          ['midiInput', 'channel', 'note', 'changes', 'nr', 'controlNames.name', 'level'           ,'level'],//, 'level'          ],
      alternativeBindings: [           ,          ,       ,          ,     ,                    , CreateInputBinding] //, defaultTextBinding ]
    });

    this.rightController.addTab(TablePanel, {
      // tabName: 'CONTROL-NAMES',
      tabName: 'CONTROLS',
      searchTable: dataModel.controlNames
      // fieldNames: ['midiInput', 'channel', 'changes', 'nr', 'controlNames.name', 'level' ],
      // alternativeBindings: { 'level': CreateInputBinding }
    });

    this.leftController.addTab(TablePanel, {
      tabName: 'UI-SHADERS',
      searchTable: dataModel.UIShaders,
      fieldNames:          ['shaderName', 'demoClass', 'controlClass'],
      alternativeBindings: [            ,            , CreateInputBinding],
      onRowSelect: (rec, ix) => dataModel.currentUIShader.index.$v = ix
    });

    this.soundAnalyzer = this.leftController.addTab(SoundAnalyzer, { canvas: this.canvas });

    // this.audioRecorder = this.settingsController.addTab(AudioRecorder);

    // this.trackPanel.trackEditor.loadMidi('https://bitmidi.com/uploads/19447.mid') // Bowie man who saved the world
    // this.trackPanel.trackEditor.loadMidi('https://bitmidi.com/uploads/6882.mid') // Animals House of the rising sun nice trumpet
    this.trackPanel.trackEditor.loadMidi('https://bitmidi.com/uploads/83417.mid') // Pink floyd great gig in the sky
    // this.trackPanel.trackEditor.loadMidi('https://bitmidi.com/uploads/95923.mid') // Yes soundchaser
    // this.trackPanel.trackEditor.loadMidi('https://bitmidi.com/uploads/16846.mid');
    // this.trackPanel.trackEditor.loadMidi('/midi/Alex Aris Martin Garrix.mid'); // Converted mp3


    // TODO: move keybindings here
    // this.codeEditor.compileElement.title = '(Alt-enter)';
    // this.codeEditor.compileElement.setAttribute('data-title', '(Ctrl-enter)');
    this.leftController.initializeDOM(this.elements.settings);
    this.rightController.initializeDOM(this.elements.content);
    this.bottomController.initializeDOM(this.elements.tracks);

    this.mainSplitter = new Splitter();
    this.mainSplitter.initializeDOM(this.elements.tracks, '--tracksHeight', true, false);

    this.renderControl = RenderControl.geInstance();
    this.renderControl.setCanvas(this.canvas);
    dataModel.loadUIShaders();
    for (let [shaderName,shaderInfo] of Object.entries(this.renderControl.getShaders())) {
      dataModel.registerUIShader(
        shaderName,
        shaderInfo.demoClass,
        shaderInfo.controlClass
      );
    }
    // dataModel.registerUIShader('timeline', 'timeline',
    //   this.trackPanel.trackEditor.trackTimeline.getShaderSource(),
    //   this.trackPanel.trackEditor.trackTimeline.compileShader);

    this.scopeDiv = this.elements.header.$el({tag:'div',cls: 'headerScope'});
    this.headerScope = new Scope(dataModel.outputName, this.scopeDiv)
  }

  initializeSynth() {
    this.synthController = new ShaderSynthController({ webgl : { canvas: this.canvas, analyzer: this.soundAnalyzer}});
    // this.filesPanel.trackEditor = this.trackPanel.trackEditor;

    this.midiInput = new MidiInput();
    this.midiInput.music =
    this.trackPanel.softwareKeyboard.music =
    this.trackPanel.trackEditor.music =
              this.synthController.music;
    dataModel.onCompile = this.synthController.compileShader.bind(this.synthController);

    // Link to controls, maybe we need system for this like interface
    // this.audioRecorder.onStartInput     = this.synthController.startInput    .bind(this.synthController);
    // this.audioRecorder.onStartRecord    = this.synthController.startRecord   .bind(this.synthController);
    // this.audioRecorder.onStopRecord     = this.synthController.stopRecord    .bind(this.synthController);
    // this.audioRecorder.onStartStreaming = this.synthController.startStreaming.bind(this.synthController);
    // this.audioRecorder.onStopStreaming  = this.synthController.stopStreaming .bind(this.synthController);

    // this.soundAnalyzer.setSynth(this.synthController.webGLSynth);
  }
}
export default Main;

// TODO: Better note timing, use time from midi in webgl track
