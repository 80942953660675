// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import PanelBase from '../../KMN-utils-browser/components/panel-base.js';
import { RenderControl } from '../../KMN-varstack-browser/components/webgl/render-control.js';
import CodeEditor from '../../KMN-varstack-browser/panels/code-editor.js';
import { addCSS, kmnClassName } from '../../KMN-varstack-browser/utils/html-utils.js';
import dataModel from '../data/dataModel.js';

// const cssStr = /*css*/`
// .${kmnClassName} {
//   --testAreaHeight: 320px;
// }

// .${kmnClassName}.ui-shader-detail .ui-code-editor {
//   height: calc(100% - var(--testAreaHeight));
// }
// .${kmnClassName}.ui-shader-detail .test-area {
//   top: calc(100% - var(--testAreaHeight));
//   height: var(--testAreaHeight);
// }
// `;

const defaultOptions = {
};

class UIShaderDetail extends PanelBase {
  constructor(options) {
    super(defaultOptions, options);
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    // addCSS('ui-shader-detail', cssStr);
    super.initializeDOM(parentElement);

    this.parentElement.classList.add('ui-shader-detail');

    this.codeEditorDiv = this.parentElement.$el({ cls: "ui-code-editor" });
    this.codeEditor = new CodeEditor({
      typeVar:   dataModel.currentUIShader.cursor.$v.demoClass,
      nameVar:   dataModel.currentUIShader.cursor.$v.shaderName,
      shaderVar: dataModel.currentUIShader.cursor.$v.shaderSource,
      onCompile: (typeName, name, source, options) => RenderControl.geInstance().compileShader(name, source, options)
    })
    this.codeEditor.initializeDOM(this.codeEditorDiv);
    this.codeEditor.show();

    // this.testArea = this.parentElement.$el({ cls: 'test-area' });
  }
}
UIShaderDetail.getTabName = () => 'UI-SHADERS';

export default UIShaderDetail;
