// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import dataModel from '../../data/dataModel.js';
import TabController from '../../../KMN-utils-browser/components/tab-controller.js';
import CodeEditor from '../../../KMN-varstack-browser/panels/code-editor.js';
import ControlsEditor from './controls-editor.js';
import { addCSS, kmnClassName } from '../../../KMN-varstack-browser/utils/html-utils.js';
import InputBuilder from '../../../KMN-varstack-browser/components/input-builder.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --shader-detail-height: 36px;
}
.${kmnClassName}.shader-details {
  height: var(--shader-detail-height);
}
.${kmnClassName}.code-controls-tabs {
  top: var(--shader-detail-height);
  height: calc(100% - var(--shader-detail-height));
}
`;

export class ShaderDetails extends PanelBase {
  constructor(options) {
    super({}, options);
    addCSS('shader-details', cssStr);
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement);

    this.shaderDetails = this.parentElement.$el({ cls: 'shader-details' });
    this.codeControlsTabs = this.parentElement.$el({ cls: 'code-controls-tabs' });

    this.instrumentSettingsBuilder = new InputBuilder(this.shaderDetails);
    const ib = this.instrumentSettingsBuilder;
    ib.addVar( dataModel.currentShader.cursor.$v.name       );
    // ib.addVar( dataModel.currentShader.cursor.$v.presetList );

    this.tabController = new TabController();
    this.tabController.initializeDOM(this.codeControlsTabs);

    // this.tabController.addTab(InstrumentList);
    this.tabController.addTab(CodeEditor, {
      typeVar:   dataModel.currentShaderTypeVar,
      nameVar:   dataModel.currentShader.cursor.$v.name,
      shaderVar: dataModel.currentShader.cursor.$v.shader,
      onCompile: dataModel.onCompile
    });

    this.tabController.addTab(ControlsEditor, {
      controlsTable: dataModel.currentShader.cursor.$v.controls
    });

    this.tabController.setSelectedTab('CODE');
    // this.lastShader = '';
    // dataModel.currentShader.cursor.$addDeferedEvent(() => {
    //   this.tabController.setSelectedTab('CODE');
    // });
  }
}
ShaderDetails.getTabName = () => 'SHADER-DETAIL';
