// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import dataModel from './data/dataModel.js'
import { otherControls } from '../KMN-gl-synth.js/otherControls.js';

import SynthDataController from './synth-data-controller.js';
import { MusicInterface } from '../KMN-gl-synth-browser/interfaces/music-interface.js';
import SynthController from '../KMN-gl-synth.js/synth-controller.js';
import { getFrequencyForNote } from '../KMN-gl-synth.js/frequency-utils.js';
import { beforeAnimationFrame } from '../KMN-utils-browser/animation-frame.js';


const emptyArray = new Float32Array();

// Handles all interaction betweeen  midiNotes => webglsynth => audioOutput
export class ShaderSynthController extends SynthController {
  constructor (options) {
    super ({
      ...options,
      ...{
        useSharedArrayBuffer: true,
        keepInBuffer: 7 * 1024,
        webgl: {
          ...options.webgl,
          ...{
            bufferWidth: 2048,
            bufferHeight: 2048,//1024,
            bufferCount: 8
          }
        },
        audioOutput: {
          sampleRate: 44100
        }
      }
    });
    this.synthDataController = null
    this.programs = {};

    /** @type {MusicInterface} */
    this.music = {
      note: this.playNote.bind(this),
      controller: this.changeControl.bind(this),
      clear: this.clearMusic.bind(this),
      preLoadPrograms: this.preLoadPrograms.bind(this),
      syncTime: this.syncTime.bind(this),
      getTime: this.getTime.bind(this),
      triggerOnTime: this.triggerOnTime.bind(this),
      deleteTrigger: this.deleteTrigger.bind(this)
    };
  }

  getSynthShaderCode(name) {
    // Default to system shader stuff
    return dataModel.getSynthShaderCode(name);
  }
  getEffectShaderCode(name) {
    // Default to system shader stuff
    return dataModel.getEffectShaderCode(name);
  }

  clearMusic() {
    super.clearMusic();
    this.synthDataController.clear();
  }

  // Since we can only start audiocontext from events
  ensureStarted () {
    if (!this.isInitialized) {
      super.ensureStarted();
      this.synthDataController = new SynthDataController(this.playData);
      dataModel.synthController = this;
      this.webGLSynth.automaticVolume = true;
      beforeAnimationFrame(this.handleAudio);
    }
  }

  handleAudio = () => {
    if (this.audioOutput && !this.isAnalyzing) {
      this.handleAudioDataRequest();
    }
    beforeAnimationFrame(this.handleAudio);
  }

  controlFromDataModel( midiInput, channel, note, nr, level) {
    if (note === -1 && channel !== -1) {
      this.playData.addControl(this.controlChangeTime, midiInput, channel, nr, level);
    }
  }

  compileShader (type, name, source, options) {
    this.ensureStarted();
    let newShader = this.webGLSynth.compileShader(type, name, source, options);
    return newShader;
  }

  // highlightKey (note, velocity) {
  //   let noteEl = document.getElementById('note_' + note + '_element');
  //   if (noteEl) {
  //     let km = note % 12;
  //     let velBright = velocity*128
  //     if ([1, 3, 6, 8, 10].indexOf(km) !== -1) {
  //       noteEl.style.backgroundColor = 'rgb(0,0,'+(64+velBright).toFixed(0)+')';
  //     } else {
  //       noteEl.style.backgroundColor = 'rgb('+
  //         (240-velBright).toFixed(0)+','+
  //         (240-velBright).toFixed(0)+',255)';
  //     }
  //   }
  // }

  // endHighlightKey(note) {
  //   let noteEl = document.getElementById('note_' + note + '_element');
  //   if (noteEl) {
  //     let km = note % 12;
  //     if ([1, 3, 6, 8, 10].indexOf(km) !== -1) {
  //       noteEl.style.backgroundColor = 'black'
  //     } else {
  //       noteEl.style.backgroundColor = 'white'
  //     }
  //   }
  // }
  getChannelColor(channel) {
    const colors = [
      [1.0, 0, 0],
      [0, 0.7, 0],
      [0, 0, 1.0],
      [0.8, 0.5, 0],
      [0, 0.5, 0.8],
      [0.8, 0, 0.8],
      [0.9, 0.5, 0],
      [0, 0.5, 0.5],
      [0.5, 0, 0.9],
      [0.5, 0.5, 0],
      [0, 0.25, 1.0],
      [0.5, 0, 1.]
    ];
    return colors[channel % 12];
  }

  handleNewBuffer() {
    const noteData = dataModel.musicKeyboard.noteData;
    noteData.fill(0);
    let entries = this.playData.getCurrentEntries(this.webGLSynth.synthTime, this.webGLSynth.bufferTime);
    for (let entry of entries) {
      // entry.channel !== 9 &&
      if ((entry.synthStart + entry.releaseTime) > this.webGLSynth.synthTime) {
        let startIx = entry.note * 4;
        noteData[startIx + 3] = entry.velocity + (entry.synthStart - this.webGLSynth.synthTime) * 0.15;
        let colorData = this.getChannelColor(entry.channel % 16);
        noteData[startIx + 0] = colorData[0];
        noteData[startIx + 1] = colorData[1];
        noteData[startIx + 2] = colorData[2];
      }
    }
  }


  getMixer (timeZone, channel, programNr) {
    if (dataModel.useCurrentInstrumentForAll
        && (timeZone !== 'midi-file')
        && (channel !== 9)) {
      const instrumentRecord = dataModel.currentInstrument.cursor;
      const synthInstrument = this.synthDataController.getInstrument(instrumentRecord,timeZone,channel);
      return synthInstrument.mixer;
    }

    const instrumentRecord = dataModel.getInstrumentForProgram(programNr);
    const synthInstrument = this.synthDataController.getInstrument(instrumentRecord,timeZone,channel);
    let mixer = synthInstrument.mixer;
    return mixer;
  }

  preLoadPrograms (programList) {
    let instruments = []
    let notes = []
    for (let programNr of programList) {
      const instrumentRecord = dataModel.getInstrumentForProgram(programNr);
      let instrument = this.synthDataController.getInstrument(instrumentRecord,'preload',-1);
      instruments.push(instrument);
      notes.push(this.playData.addNote(0,'preload',-1,instrument.mixer,{ note:0, velocity:0.0001, channel: -1 }));
    }
    this.webGLSynth.calculateSamples();
    for (let instrument of instruments) {
      this.synthDataController.removeInstrument(instrument)
    }
    for (let note of notes) {
      note.release(0.001,0);
    }
  }

  changeControl(time, timeZone, channel, controlType, value) {
    this.ensureStarted();

    // Check for preload channel
    if (channel===-1) {
      if (controlType === otherControls.program) {
        const instrumentRecord = dataModel.getInstrumentForProgram(value);
        this.synthDataController.getInstrument(instrumentRecord,timeZone,channel);
      }
      return
    }

    // Set the time for the callback from datamodel
    this.controlChangeTime = time;
    dataModel.updateControl(timeZone, channel, -1, controlType, value);
    this.controlChangeTime = -1;

    if (controlType === otherControls.program) {
      this.programs[timeZone + '_' + channel] = value
    }
    // console.log('control: ',channel, MidiControlList[id], id, value);
  }

  playNote (time, timeZone, channel, note, velocity) {
    this.ensureStarted();
    // @ts-ignore
    if (this.audioOutput.sd) {
      // @ts-ignore
      let pt = this.audioOutput.sd.contextTime;// this.audioOutput.getContextTime();
      console.log('midi post time: ', pt, note, getFrequencyForNote(note));
    }

    // Since messages are sequential this should work without timing program
    let program = this.programs[timeZone + '_' + channel] || 0;
    // Lets's implement this MIDI hack here, (9(10) is drums? why not 15 instead of arbitrary decimal?)
    // TODO Bank nr's
    let programNr = (channel % 16) === 9 // Midi tracks have tracknr*16 added to the channel in my implementation
           ? dataModel.getNrForProgram(true, note, 0)
           : dataModel.getNrForProgram(false, program, 0);

    let mixer = this.getMixer(timeZone, channel, programNr);

    const noteData = { note, velocity, program, channel }
    const noteEntry = this.playData.addNote(time, timeZone, channel, mixer, noteData);
    return {
      changeControl: (time, controlType, value) => {
        noteEntry.changeControl(time, controlType, value);
        dataModel.updateControl(timeZone, channel, note, controlType, value);
      },
      release: (time, velocity) => {
        noteEntry.release(time, velocity);
        // if (velocity>=0) {
        //   dataModel.updateControl(timeZone, channel, note, otherControls.releaseVelocity, velocity);
        // }
      }
    }
  }
}
