import { MidiToMusic } from '../KMN-gl-synth-browser/components/midi-to-music.js';
class MidiInput extends MidiToMusic {
  constructor() {
    super();

    this.messageCount = 0;
    if (!navigator.requestMIDIAccess) {
      console.error(
        'No MIDI API available, chrome disables it on non https and other browsers do not support it (yet)'
      );
    } else {
      navigator.requestMIDIAccess().then(
        midi => this.midiReady(midi),
        err => console.error('Error requesting MIDI access: ', err)
      );
    }
  }

  midiMessage(event) {
    if (!this.music) {
      console.error('No music interface to call from MidiInput')
      return;
    }

    const cmd = event.data[0] >> 4;
    const channel = event.data[0] & 0x0F;
    const note = event.data[1];
    const value = event.data.length > 2 ? event.data[2] : 1;
    const time = event.timeStamp / 1000.0;

    this.handleMessage(event.target.name, channel, cmd, note, time, value);
  }

  initDevices(midi) {
    const inputs = midi.inputs.values();
    for (let input of inputs) {
      // console.info('Listening to: ', input);
      // TODO: clear old listeners
      input.addEventListener('midimessage', this.midiMessage.bind(this));
    }
  }

  midiReady(midi) {
    // For if sombody plugs-in a keyboard later
    // midi.addEventListener('statechange', event =>
    //   this.initDevices(event.target)
    // );
    this.initDevices(midi);
  }
}

export default MidiInput;
