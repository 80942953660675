import { Types } from '../../KMN-varstack.js/varstack.js';
import LocalStorageBinding from '../../KMN-varstack-browser/utils/local-storage-binding.js';
import TableCursor from '../../KMN-varstack.js/structures/table-cursor.js';
import { StringVar } from '../../KMN-varstack.js/vars/string.js';
import { MusicKeyboard } from '../../KMN-gl-synth-browser/components/webgl/music-keyboard.js';
import { UndoStack } from '../../KMN-varstack.js/utils/undo-stack.js';
import { ComponentShaders, registerComponentShader } from '../../KMN-varstack-browser/components/webgl/component-shaders.js';

// Build our data definition using my new varstack lib
// which I'm writing as I go in this project, since
// it is the 1st implementation for it.
// It is based on the vars i wrote for my puzzlesolver in Delphi a century ago
// It's ment to generate a descriptive enough model so the rest can be handle by generic implementations
// And it functions as a single point of truth in the program, it's THE model on which views are generated
Types.addRecord('PresetValue', {
  name: 'String:key',
  level: 'Float:value'
})
Types.addArray('PresetCollection', 'PresetValue');

Types.addRecord('ControlDefinition', {
  name: 'String:key',
  definition: 'String:defval>Float:range>0..1',// TODO make something like Types.varstack.VarDefinition instead of string stuff
  defaultLevel: 'Any:value,defref>definition',
  midiControlNr: 'Int:lookup>controlNames.nr', 
  midiControlName: 'String:ref>controlNames.name', 
})
Types.addArray('ControlCollection', 'ControlDefinition');

Types.addRecord('PresetRecord', {
  name: 'String:key',
  data: 'PresetCollection:value'
})
Types.addArray('PresetList', 'PresetRecord');

Types.addRecord('ShaderRecord', {
  name: 'String:key',
  shader: 'String:value',
  controls: 'ControlCollection',
  presetList: 'PresetList'
});
Types.addArray('ShaderTable', 'ShaderRecord');

Types.addRecord('EffectPass', {
  shader: 'String:lookup>effectShaders.name,key',
  preset: 'String:lookup>effectShaders.presetList.name,value',
});
Types.addArray('EffectCollection', 'EffectPass');

Types.addRecord('InstrumentRecord', {
  name: 'String:ro',
  shader: 'String:lookup>inputShaders.name',
  preset: 'String:lookup>inputShaders.presetList.name',
  preEffects: 'EffectCollection',
  postEffects: 'EffectCollection',
  presetList: 'PresetList'
});

Types.addArray('InstrumentTable', 'InstrumentRecord');
Types.addRecord('MapRecord', {
  nr: 'Int:ro',
  name: 'String:ro',
  instrument: 'String:lookup>instrumentTable.name',
  preset: 'String:lookup>instrumentTable.presetList.name'
});
Types.addArray('MapTable', 'MapRecord');

Types.addRecord('MidiMapLookup', {
  nr: 'Int:lookup>midiMap.nr,key',
  name: 'String:ref>midiMap.name,value'
});

Types.addRecord('ControlName', {
  nr: 'Int:key',
  definition: 'String',
  name: 'String',
  description: 'String:value'
})
Types.addArray('ControlNames', 'ControlName');

Types.addRecord('ControlRecord', {
  midiInput: 'String:key,ro',
  channel: 'Int:key,ro',
  note: 'Int:key,ro',
  nr: 'Int:key,ro,lookup>controlNames.nr',
  level: 'Any:defref>controlNames.definition', // The definition is loaded on 1st access and copied from this var
  changes: 'Int:ro,nostore'
})
Types.addArray('ControlList', 'ControlRecord');

Types.addRecord('UIControlRecord', {
  controlClass: 'String:key,ro',
})
Types.addArray('UIControlList', 'UIControlRecord');

Types.addRecord('UIShaderRecord', {
  demoClass: 'String:key,ro',
  controlClass: 'String:lookup>UIControls.controlClass',
  shaderName: 'String:key,ro',
  shaderSource: 'String'
})
Types.addArray('UIShaderList', 'UIShaderRecord');


/** @type {typeof import('../../../TS/data-model').MainRecord} */ // @ts-ignore
const MainRecord = Types.addRecord('MainRecord', {
  instrumentTable: 'InstrumentTable',

  midiMap: 'MapTable',

  inputShaders: 'ShaderTable',
  effectShaders: 'ShaderTable',

  controlTable: 'ControlList',
  controlNames: 'ControlNames',

  UIControls: 'UIControlList',
  UIShaders: 'UIShaderList'
});

class GlobalDataModel extends MainRecord {
  constructor() {
    super()

    // This needs to be setup before loading
    this.undoStack = new UndoStack();
    this.undoStack.addVarTree(this.controlNames);
    this.undoStack.addVarTree(this.inputShaders);
    this.undoStack.addVarTree(this.effectShaders);
    this.undoStack.addVarTree(this.instrumentTable);
    this.undoStack.addVarTree(this.midiMap);

    this.useCurrentInstrumentForAll = true;

    this.currentMidiMap = new TableCursor(this.midiMap);
    this.currentInstrument = new TableCursor(this.instrumentTable);
    this.currentShader = new TableCursor(this.inputShaders); // Types.ShaderRecord);
    this.currentShaderTypeVar = new StringVar();
    this.currentShader._index.$addEvent(()=> {
      this.currentShaderTypeVar.$v = (this.currentShader.table === this.inputShaders) ? 'synth' : 'effect'
    })
    this.currentUIShader = new TableCursor(this.UIShaders); // Types.ShaderRecord);

    this.currentShader.table = this.inputShaders;

    this.currentInstrument.cursor.$v.shader.$addEvent(() => {
    // this.currentInstrument.index.$addEvent(() => {
      // Reset current shader to input shader on change
      this.currentShader.table = this.inputShaders;
      this.currentShader.index.$v = this.inputShaders.findIx('name',this.currentInstrument.cursor.$v.shader.$v);
    });

    import('./midi-control-names.js').then((m) => {
      this.inputShaderStore = new LocalStorageBinding(this.controlNames, 'ControlNames', m.default);
    });
    import('./webgl-synth-synths.js').then((m) => {
      this.inputShaderStore = new LocalStorageBinding(this.inputShaders, 'InputShaders', m.default);
    });
    import('./webgl-synth-effects.js').then((m) => {
      this.effectShaderStore = new LocalStorageBinding(this.effectShaders, 'EffectShaders', m.default);
    });
    import('./instrument-list.js').then((m) => {
      this.instrumentStore = new LocalStorageBinding(this.instrumentTable, 'Instruments', m.default);
      this.currentInstrument.index.$v = 0;
    });
    import('./midi-map.js').then((m) => {
      this.midiMapStore = new LocalStorageBinding(this.midiMap, 'MidiMap', m.default);
    });

    this.midiEditor = null;
    this.synthController = null;
    this.onCompile = null;
    // this.$addEvent(()=>console.log('change'));

    this.outputName = new StringVar();
    this.outputName.$v = '#output';
    this.outputName.$parent = this;
    /** @type {MusicKeyboard} */
    this.musicKeyboard = undefined;

    // TODO 100ms is to short, find async trigger for this
    setTimeout(() => this.undoStack.loadingFinished(), 1000);
  }

  loadUIShaders() {
    this.uiShaderStore = new LocalStorageBinding(this.UIShaders, 'UIShaders');
  }

  /**
   * 
   * @param {*} controlClass 
   */
   registerUIControl(controlClass) {
    let rec = {
      controlClass: controlClass.name
    }
    let storedRec = this.UIControls.findFields(rec);
    if (!storedRec) {
      this.UIControls.add(rec);
    }
   }
  
  /**
   * 
   * @param {string} shaderName 
   * @param {*} demoClass 
   * @param {*} controlClass 
   */
  registerUIShader(shaderName, demoClass, controlClass) {
    let rec = {
      shaderName
    }
    this.registerUIControl(controlClass)
    let storedRec = this.UIShaders.findFields(rec);
    if (storedRec) {
      registerComponentShader(storedRec.shaderName.$v, storedRec.shaderSource.$v);
    } else {
      rec.demoClass = demoClass.name;
      rec.controlClass = controlClass.name;
      rec.shaderSource = ComponentShaders[shaderName];
      this.UIShaders.add(rec);
    }
  }

  getInstrumentsUsed() {
    if (this.midiEditor) {
      let programNrs = [];
      for (let program of this.midiEditor.getInstrumentsUsed()) {
        programNrs.push(program);
      }
      for (let program of this.midiEditor.getPercussionUsed()) {
        programNrs.push(program + 512);
      }
      return programNrs;
    }
  }

  /**
   * @param {import('../../../TS/data-model.js').ControlRecord} rec 
   */
  controlLevelChanged = (rec) => {
    // console.log(rec);
    if (this.synthController) {
      this.synthController.controlFromDataModel(
        rec.midiInput.$v,
        rec.channel.$v,
        rec.note.$v,
        rec.nr.$v,
        rec.level.$v);
      if (this.currentInstrument) {
        let inputShaderControls = this.currentInstrument.cursor.$v.inputShaders.$v.controls;
      // if (this.currentShader) {
      //  let inputShaderControls = this.currentShader.cursor.controls;
        if (this.currentShader.cursor.$v.controls && inputShaderControls.length) {
          let controlRec = inputShaderControls.find('midiControlNr',rec.nr.$v);
          if (controlRec) {
            console.log('ctrl:',controlRec.defaultLevel.$v, rec.level.$v);
            controlRec.defaultLevel.$v = rec.level.$v;
            console.log(inputShaderControls, this.currentShader.cursor.$v.controls);
          }
        }
      }
    }
  }

  updateControl(midiInput, channel, note, nr, level) {
    let rec = { nr, channel, midiInput, note};
    let storedRec = this.controlTable.findFields(rec);
    if (storedRec) {
      storedRec.level.$v = level;
      storedRec.changes.$v = storedRec.changes.$v + 1;
    } else {
      rec.level = level;
      rec.changes = 0;
      storedRec = this.controlTable.add(rec);
      storedRec.level.$addEvent(this.controlLevelChanged.bind(this,storedRec));
    }
  }

  getSynthShaderCode(name) {
    let shaderRec = this.inputShaders.find('name',name);
    return shaderRec.shader.$v;
  }

  getEffectShaderCode(name) {
    let shaderRec = this.effectShaders.find('name',name);
    return shaderRec.shader.$v;
  }

  getNrForProgram(isDrum, noteOrProgram, bankNr) {
    return (noteOrProgram + (isDrum ? 512 : 0)) + (bankNr * 1024);
  }

  getInstrumentForProgram(programNr) {
    programNr = ~~programNr; // Prevent strings here
    // TODO remove this hack
    if (programNr === 2048) { // Stream input
      return this.instrumentTable.find('name', 'streamInput')
    }
    if (programNr===0) {
      programNr = 1;
    }
    //  midi map table for seperation of midi instruments and intrument programs
    let mapIx = this.midiMap.findIx('nr', programNr);
    if (mapIx < 0) {
      console.warn('No instrument map for program: ', programNr);
      return this.instrumentTable.array[0];
    }
    // This would return the instrumenTable entry that doesn't get updated if the midimap changes
    // return this.instrumentTable.find('name',mapRec.instrument.$v)

    // This returns the instrumenTable look-up entry from the midiMap record that does get updated with changes in midiMap
    // I've also bound updates from instrumentTable(by lookup) in varstack with an updateTo back to this record so the
    // changes in the instrumentrecord also affect this record
    return this.midiMap.array[mapIx].instrumentTable;
  }
}

const instance = new GlobalDataModel()

export default instance
