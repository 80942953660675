import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import { Types } from '../../../KMN-varstack.js/varstack.js';

import InputBuilder from '../../../KMN-varstack-browser/components/input-builder.js';
import { TableBuilder } from '../../../KMN-varstack-browser/components/table-builder.js';
import { addCSS, kmnClassName } from '../../../KMN-varstack-browser/utils/html-utils.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --bitMidiHeaderHeight: 38px;
}
.${kmnClassName}.bitMidiSearchHeader {
  height: var(--bitMidiHeaderHeight);
}
.${kmnClassName}.bitMidiSearchTableDiv {
  top: var(--bitMidiHeaderHeight);
  height: calc(100% - var(--bitMidiHeaderHeight));
}
.${kmnClassName}.bitMidiImage {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.${kmnClassName}.BitMidiSearchTable .plays {
  text-align: right;
  width: 50px;
}
.${kmnClassName}.BitMidiSearchTable .views {
  text-align: right;
  width: 50px;
}
.${kmnClassName}.bitMidiSearchHeader td.isLabel {
  width: 120px;
}
`
const defaultOptions = {
};

Types.addRecord('BitMidiSearchRecord', {
  name: 'String:ro',
  views: "Int",
  plays: "Int",
  // TODO date time var createdAt: "2018-07-13 20:10:09",
  // updatedAt: "2020-06-13 04:53:03",
  url: 'String:ro',
  downloadUrl: 'String:ro',
});
Types.addArray('BitMidiSearchTable', 'BitMidiSearchRecord');

class BitMidiSearch extends PanelBase {
  constructor(options) {
    super(defaultOptions, options);

    this.searchStr = new Types.String();
    this.bitMidiSearchTable = new Types.BitMidiSearchTable();
    this.searchStr.$addEvent( (x) => this.searchBitMidi(x.$v) );

    this.onLoadMidi = null;
  }

  searchBitMidi(searchStr) {
    fetch(`https://bitmidi.com/api/midi/search?q=${encodeURIComponent(searchStr)}&page=0`)
    .then(response => {
      return response.json();
    })
    .then(data => {
      this.bitMidiSearchTable.clearRecords();
      for (let ix=0; ix < data.result.results.length; ix++) {
        this.bitMidiSearchTable.add(data.result.results[ix]);
      }
      this.bitMidiSearchTableEl.updateTable();
    });
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement);
    addCSS('bit-midi-search',cssStr);

    this.bitMidiSearchHeaderDiv = this.parentElement.$el({ cls: "bitMidiSearchHeader" });
    this.bitMidiSearchTableDiv = this.parentElement.$el({ cls: "bitMidiSearchTableDiv" });

    this.searchInputBuilder = new InputBuilder(this.bitMidiSearchHeaderDiv);
    this.searchInput = this.searchInputBuilder.addVar(this.searchStr,' ');
    this.bitMidiLink = this.searchInput.label.parentElement.$el({tag: 'a'});
    this.bitMidiLink.href = 'https://bitmidi.com';
    this.bitMidiLink.setAttribute('target', '_blank');
    this.bitMidiImage = this.bitMidiLink.$el({ tag:'img', cls: 'bitMidiImage'});
    // this.bitmidiImage.crossOrigin = 'anonymous';
    this.bitMidiImage.src = '/images/bitmidi.svg';

    this.bitMidiSearchTableEl = new TableBuilder (
      this.bitMidiSearchTableDiv,
      this.bitMidiSearchTable,
      {
        fieldNames: ['name','views','plays'],
        onRowClick: rec => {
          // @ts-ignore Maybe find a way to add all dynamic records to typescript
          this.onLoadMidi('https://bitmidi.com' + rec.downloadUrl.$v);
        }
      });
  }
}
BitMidiSearch.getTabName = () => 'BIT-MIDI';

export default BitMidiSearch;
