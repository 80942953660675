// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import {
  TrackData,
  // TrackEntry,
  TrackNote,
  TrackControl,
  TrackTempo,
  TrackInstrument,
  TrackTimeSignature
} from './arrangement-data.js';
import { otherControls } from '../../KMN-gl-synth.js/otherControls.js';
import { Types } from '../../KMN-varstack.js/varstack.js';
import TrackEditor from './arrangement-editor.js';

const damperPedalControl = 64


Types.addRecord('MidiTempoAndTimeSignature', {
  microsecondsPerBeat: 'Float:range>80000..2400000',
  numerator: 'Float',
  denominator: 'Float',
  metronome: 'Float',
  thirtyTwoSeconds: 'Float'
});

Types.addRecord('PlayPosition', {
  position: 'Float',
  length: 'Float'
});

/**
 * @typedef {{openNotes: TrackNote[], controls: Record<number,number>, track: TrackData}} TrackInfo
 */
class TrackPlayer {
  /**
   * @param {TrackEditor} owner 
   */
  constructor (owner) {
    this.owner = owner;
    this.clearStatus();
    this.playingVar = new Types.Bool();
    this.playPosition = new Types.Float();
    this.tempoAndTime = new Types.MidiTempoAndTimeSignature();
    this.tempoAndTime.microsecondsPerBeat.$addEvent(() => {
      this.changeTempo(undefined, this.tempoAndTime.microsecondsPerBeat.$v);
    })
    this.haltUpdateFromTimeSlider = false;
    this.playPosition.$addEvent(() => {
      if (!this.haltUpdateFromTimeSlider) {
        let newVal = (this.playPosition.$v * this.totalTrackTime) // allTracks.entries.length);
        if (Math.abs(this.playStatus.entryIx - newVal) > 5) {
          this.changePosition(newVal);
          // console.log('.',this.playStatus.entryIx, newVal);
        }
      }
    });
    this.totalTrackTime = 0;
    this.maxEntryTime = 0;
    this.lastEntryTime = 0;
    this.playingVar.$addEvent((p) => {
      if (p.$v) {
        this.play();
      } else {
        this.stop();
      }
    });
  }
  
  clearStatus() {
    if (this.playStatus) {
      if (this.playStatus.timer) {
        this.owner.music.deleteTrigger(this.playStatus.timer);
      }

      let entryToClose;

      for (let trackInfo of Object.values(this.playStatus.trackInfo)) {
        while (entryToClose = trackInfo.openNotes.shift()) {
          if (entryToClose.endTimer) {
            clearTimeout(entryToClose.endTimer);
          }
          entryToClose.playNote?.release(this.getTimeMs(entryToClose.startTime) / 1000.0, entryToClose.playNote.releaseVelocity);
          entryToClose.setPlaying();
        }
      }
    }

    this.playStatus = {
      timer: undefined,
      state: 0,
      /** @type {Record<string,TrackInfo>} */
      trackInfo: {},
      entryIx: 0,
      /**
       * @param {TrackData} track 
       * @returns {TrackInfo}
       */
      getTrackInfo(track) {
        let key = track.getKey();
        let ti = this.trackInfo[key]
        if (!ti) {
          this.trackInfo[key] = ti = {
            openNotes: [],
            controls: {},
            track
          }
        }
        return ti;
      }
    };
  }

  isPlaying() {
    return this.playStatus.state === 1;
  }

  stop() {
    this.clearStatus();
  }

  changeTempo(startTime, newTempo) {
    let newTime = this.playStatus.currentTime * 1000.0;
    if (startTime === undefined) {
      startTime = this.tickBase + 
        (newTime - this.tickTime) * this.ticksPerBeat /
        this.tickTempo.microsecondsPerBeat * 1000.0;
    }
    this.tickTime = newTime;
    this.tickTempo.microsecondsPerBeat = newTempo;
    this.tickBase = startTime;
  }

  changePosition(newPosition) {

    this.searchTime = newPosition;
    this.tickBase = 0;
    this.tickTime = 0;

    if (this.playStatus.timer) {
      this.owner.music.deleteTrigger(this.playStatus.timer);
    }
    // let start = performance.now();

    this.clearStatus();

    this.tickTime = 0; // this.playStatus.currentTime;
    this.lastEntryTime = 0;
    while (this.lastEntryTime < this.searchTime) {
      if (!this.doNext(false)) {
        // End of song reached
        return
      }
    }

    let entry = this.peekNextEntry();
    if (!entry) {
      // Nothing to play
      return;
    }


    this.playStatus.currentTime = this.searchTime;
    this.tickTime = this.playStatus.currentTime * 1000.0;
    this.tickBase = this.searchTime * this.ticksPerBeat /
                    this.tickTempo.microsecondsPerBeat * 1000000.0;

    this.owner.music.clear();
    this.owner.music.syncTime('midi-file', this.playStatus.currentTime);

    for (let trackInfo of Object.values(this.playStatus.trackInfo)) {
      // update all missed controls from trackInfo
      for (let ctrl of Object.entries(trackInfo.controls)) {
        this.owner.music.controller(this.getTimeMs(entry.startTime) / 1000.0 - 0.001,
          'midi-file',
          trackInfo.track.getUniqueChannelNr(),
          // @ts-ignore: this is a number but typescript is missing a defnition for entries that handles that
          Number.parseInt(ctrl[0]),
          ctrl[1]);
      }
      // update all missed notes from trackInfo
      for (let note of Object.values(trackInfo.openNotes)) {
        if (note.endTime > this.searchTime) {
          this.lastEntryTime = this.getTimeMs(note.startTime) / 1000.0
          // TODO: this endTime stops just a little to early
          note.endTime = 
            this.lastEntryTime + 
            note.duration / this.ticksPerBeat *
            this.tickTempo.microsecondsPerBeat / 1000000.0;
          this.playNote(note);
          // console.log('mid note: ',note);
        } else {
          console.error('false note start: ',note);
        }
      }
    }

    let ps = this.playStatus;

    const nextEntryTime = this.getTimeMs(entry.startTime);
    ps.timer = this.owner.music.triggerOnTime('midi-file', nextEntryTime / 1000.0, this.playNext);
  }

  getTimeMs(entryTime, offset) {
    return this.tickTime +
      (entryTime - this.tickBase + ~~offset) / this.ticksPerBeat *
      this.tickTempo.microsecondsPerBeat / 1000.0;
  }

  getNextEntry = () => this.allTracks.entries[ this.playStatus.entryIx++];
  peekNextEntry = () => this.allTracks.entries[ this.playStatus.entryIx ];

  prepareTracks(tracks, ticksPerBeat) {
    this.ticksPerBeat = ticksPerBeat;

    this.allTracks = new TrackData();
    let keys = Object.keys(tracks);
    if (keys.length) {
      for (let ix = 0; ix < keys.length; ix++) {
        const key = keys[ix];
        const curTrack = tracks[key];
        // if (ix===3) {
          Array.prototype.push.apply(this.allTracks.entries,curTrack.entries);
        // }
      }
    }
    this.allTracks.entries = this.allTracks.entries.sort((a,b) => a.startTime - b.startTime);

    // this.totalPosTime = 0;
    // this.totalPosTime0 = 0;
    this.tickBase = 0;
    this.tickMetronome = 120;
    this.tickTime = 0;
    // Add default timing
    this.tickSignature = new TrackTimeSignature(undefined, 0, 4, 4, 120, 8);
    this.tickTempo = new TrackTempo(undefined, 0, 500000);

    this.playStatus.currentTime = 0;

    // Just find the endtime, don't collect notes
    this.searchTime = 1000000.0;
    while (this.doNext(false)) {
    //   for (let trackInfo of Object.values(this.playStatus.trackInfo)) {
    //     for (let note of trackInfo.openNotes) {
    //       if (note.endTime < this.lastEntryTime) {
    //         this.closeNote(note,trackInfo);
    //       }
    //     }
    //   }
    }
    this.totalTrackTime = this.maxEntryTime;

    this.tempoAndTime.$v = this.tickSignature;
    this.tempoAndTime.$v = this.tickTempo;
    this.playPosition.$v = 0;

    // this.changePosition(0);
  }

  play() {
    this.owner.music.clear();
    // Preload the instruments
    this.owner.music.preLoadPrograms(this.owner.usedInstruments);
      
    let ps = this.playStatus;
    ps.state = 1;

    if (this.allTracks.entries.length - ps.entryIx <= 0) {
      this.playStatus.timer = undefined;
      return;
    }

    if (this.allTracks.entries.length>0) {
      // let startSkip = 0;
      let entry = this.allTracks.entries[0];
      for (let ix = 0; ix < this.allTracks.entries.length; ix++) {
        let entry = this.allTracks.entries[ix];
        if (entry instanceof TrackNote) {
          //startSkip = entry.startTime / this.ticksPerBeat *
          //            this.tickTempo.microsecondsPerBeat / 1000.0;
          break;
        }
      }
      this.changePosition(0);
    } else {
      this.stop();
    }
  }

  playNext = () => {
    if (!this.doNext(true)) {
      return
    }

    let ps = this.playStatus;
    let nextEntry = this.peekNextEntry();
    if (nextEntry) {

      // Does signature influence timing?
      // (this.tickSignature.thirtyTwoSeconds * this.tickSignature.numerator / 32);
      const nextEntryTime = this.getTimeMs(nextEntry.startTime);
      const nextTime = (nextEntryTime - this.owner.music.getTime('midi-file') * 1000.0)

      // Somtimes a lot of msg's are on the same time, sceduling them all
      // with setTimeout will give to much lag so we recursivly handling them here
      // Maybe fix this in a better player pipeline
      if (nextTime <= 13) { // TODO use synth bufferTime
        this.playNext();
      } else {
        ps.timer = this.owner.music.triggerOnTime('midi-file', nextEntryTime / 1000.0, this.playNext);
        // ps.timer = setTimeout(this.playNext, nextTime);
      }
    } else {
      ps.timer = undefined;
      this.stop();
    }
    // let start0 = performance.now();
    // this.pos0 = this.playStatus.entryIx / this.allTracks.entries.length;
    // let stop0 = performance.now();
    // this.totalPosTime0 +=  stop0-start0;

    // let start = performance.now();

    this.haltUpdateFromTimeSlider = true;
    this.playPosition.$v = this.playStatus.currentTime / this.totalTrackTime;
    this.haltUpdateFromTimeSlider = false;
    // let stop = performance.now();
    // this.totalPosTime +=  stop-start;
  }
  /**
   * Scedule the playing of a note
   * @param {TrackNote} noteEntry 
   */
  playNote(noteEntry) {
    let ps = this.playStatus;
    let trackInfo = ps.getTrackInfo(noteEntry.track);
    let playNote = this.owner.music.note(this.lastEntryTime,
      'midi-file',
      noteEntry.track.getUniqueChannelNr(),
      noteEntry.note,
      noteEntry.velocity);

    if (!isFinite(noteEntry.duration)) {
      console.error('Note without duration');
    }
    trackInfo.openNotes.push(noteEntry);
    noteEntry.setPlaying(playNote);

    noteEntry.endTimer = this.owner.music.triggerOnTime(
      'midi-file',
      noteEntry.endTime,
      () => {
        this.closeNote(noteEntry, trackInfo);
      }
    ); 
    // noteEntry.endTimer = setTimeout(() => {
    //     this.closeNote(noteEntry, trackInfo);
    //   },
    //   (noteEntry.endTime - this.owner.music.getTime('midi-file')) * 1000.0
    //   // (noteEntry.endTime - this.lastEntryTime) * 1000.0
    // ); 
  }
  closeNote(noteEntry, trackInfo) {
    let playNote = noteEntry.playNote;
    // We could do partial dampening by giving the end note a damp% or pass it as release velocity
    if (!(trackInfo.controls[damperPedalControl] > 0.99)) {
      playNote?.release(this.getTimeMs(noteEntry.startTime, noteEntry.duration) / 1000.0, noteEntry.releaseVelocity);
      let ix = trackInfo.openNotes.indexOf(noteEntry);
      trackInfo.openNotes.splice(ix, 1);
      noteEntry.setPlaying();
    } else {
      noteEntry.readyForRelease = true
    }
    noteEntry.endTimer = undefined;
  }
  doNext(doOutput) {
    let ps = this.playStatus;
    let entry = this.getNextEntry();
    if (!entry) {
      return false;
    }
    this.lastEntryTime = this.getTimeMs(entry.startTime) / 1000.0
    if (this.lastEntryTime > this.maxEntryTime) {
      this.maxEntryTime = this.lastEntryTime
    }
    ps.currentTime = this.lastEntryTime;
    if (entry instanceof TrackNote) {

      // Is this correct if the tempo changes during play?
      entry.endTime = 
        this.lastEntryTime + 
        entry.duration / this.ticksPerBeat *
        this.tickTempo.microsecondsPerBeat / 1000000.0;

      if (entry.endTime > this.maxEntryTime) {
        this.maxEntryTime = entry.endTime
      }

      if (doOutput) {
        this.playNote(entry)
      } else {
        if (entry.endTime > this.searchTime) {
          let trackInfo = ps.getTrackInfo(entry.track);
          trackInfo.openNotes.push(entry);
        }
      }
    } else if (entry instanceof TrackInstrument) {
      entry.track.instrument = entry.instrument;
      let trackInfo = ps.getTrackInfo(entry.track);
      trackInfo.controls[otherControls.program] = entry.instrument.program;
      if (doOutput) {
        this.owner.music.controller(
          this.lastEntryTime,
          'midi-file',
          entry.track.getUniqueChannelNr(),
          otherControls.program,
          entry.instrument.program);
      }
      // entry.track.updateLabel();
    } else if (entry instanceof TrackTempo) {
      this.tickTempo.microsecondsPerBeat = entry.microsecondsPerBeat;
      this.tempoAndTime.$v = entry;

      this.changeTempo(entry.startTime, entry.microsecondsPerBeat)
    } else if (entry instanceof TrackTimeSignature) {
      this.tempoAndTime.$v = entry;
      this.tickSignature = entry;
      this.tickBase = entry.startTime;
      this.tickTime = ps.currentTime;
    } else if (entry instanceof TrackControl) {
      let trackInfo = ps.getTrackInfo(entry.track);

      // console.log('Control: ',entry.controlType, entry.value, trackInfo
      // TODO: check if this is correct, there could be multiple channels in track? or did i already split that with tracknr?
      trackInfo.controls[entry.controlType] = entry.value;
      if (doOutput) {

        this.owner.music.controller(
          this.getTimeMs(entry.startTime) / 1000.0,
          'midi-file',
          entry.track.getUniqueChannelNr(),
          entry.controlType,
          entry.value);
      }

      // This animates the note pitch
      for (let noteEntry of trackInfo.openNotes) {
        noteEntry.changeControl(
          this.getTimeMs(entry.startTime) / 1000.0,
          entry.controlType,
          entry.value);
      }

      // TODO move this to webgl-synth-data so it works for keyboards
      if (damperPedalControl === entry.controlType && entry.value <= 0.99) {
        let entryToClose;
        let stillOpen = [];
        while (entryToClose = trackInfo.openNotes.shift()) {
          if (entryToClose.readyForRelease) {
            entryToClose.playNote?.release(this.getTimeMs(entry.startTime) / 1000.0, entryToClose.playNote.releaseVelocity);
            entryToClose.setPlaying();
          } else {
            stillOpen.push(entryToClose);
          }
        }
        Array.prototype.push.apply(trackInfo.openNotes, stillOpen);
      }
      // console.log('controlChange: ', ps.controls);
    }
    return true;
  }
}

export default TrackPlayer