// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import { SampleRecorder } from '../KMN-gl-synth.js/sample-recorder.js';
import SynthPlayData, { SynthMixer } from '../KMN-gl-synth.js/webgl-synth-data.js';

class SynthInstrument {
  constructor (owner) {
    // The data for this instrument
    this.owner = owner; // SynthDataController
    /** @type {import('../../TS/data-model.js').InstrumentRecord} */
    this.instrumentRecord = null; // Vars.InstrumentRecord
    this.preMixer = null;  // SynthPlayData.Mixer
    /** @type {SynthMixer} */
    this.postMixer = null;  // SynthPlayData.Mixer
    this.handleShaderChangeBound      = this.handleShaderChange     .bind(this);
    this.handlePreEffectsChangeBound  = this.handlePreEffectsChange .bind(this);
    this.handlePostEffectsChangeBound = this.handlePostEffectsChange.bind(this);
  }

  get mixer () {
    return this.preMixer;
  }

  loadInstrument(instrumentRecord) {
    this.instrumentRecord = instrumentRecord;
    const inputShaderName = instrumentRecord.shader.$v;
    this.preMixer = new SynthMixer(this.owner.playData.output, inputShaderName);// defaults to outputmixer
    if (inputShaderName === 'playInput') {
      console.log('**** Sample recorder started.');
      this.preMixer.setAudioStreams(new SampleRecorder(this.owner.playData, this.preMixer, 15 * 60 * 44100), this.owner.playData.synth);
    }
    this.owner.playData.registerStartMixer(this.preMixer);

    this.instrumentShaderEvent = this.instrumentRecord.shader.$addEvent(this.handleShaderChangeBound);
    this.instrumentPreEffectsEvent = this.instrumentRecord.preEffects.$addEvent(this.handlePreEffectsChangeBound);
    this.instrumentPostEffectsEvent = this.instrumentRecord.postEffects.$addEvent(this.handlePostEffectsChangeBound);
    // TODO create mixers in playdata
    // TODO bind to effect list changes to update mixers
    this.handlePreEffectsChange();
    this.handlePostEffectsChange();
  }

  handleShaderChange () {
    this.preMixer.inputShaderName = this.instrumentRecord.shader.$v;
    // console.log('Instrument input shader changed: ',this.preMixer.data.shader);
    this.owner.playData.invalidatePlan();
  }

  handlePreEffectsChange () {
    let effects = this.instrumentRecord.preEffects.array.map(x => x.shader.$v);
    this.preMixer.setEffects(effects);
    this.owner.playData.invalidatePlan();
    return

    // // TODO: Bind for update
    // /** @type {SynthShaderInfo[]} */
    // let preEffects = []
    // for (let effect of this.instrumentRecord.preEffects.array) {
    //   // TODO: Bind for update
    //   let presetList = effect.effectShaders.$v.presetList;
    //   let preset = presetList.find('name',effect.preset.$v);
    //   let controls = effect.effectShaders.$v.controls;

    //   let shaderInfo = new SynthShaderInfo(effect.shader,controls)
    // }
    // // console.log('Instrument pre effects changed: ',effects);
  }

  handlePostEffectsChange () {
    let effects = this.instrumentRecord.postEffects.array.map(x => x.shader.$v);
    if (effects.length === 0) {
      this.preMixer.mixer = this.owner.playData.output;
    } else {
      if(!this.postMixer) {
        this.postMixer = new SynthMixer(this.owner.playData.output)
      }
      this.preMixer.mixer = this.postMixer;
      this.postMixer.setEffects(effects);
    }
    // console.log('Instrument post effects changed: ',effects);
    this.owner.playData.invalidatePlan();
  }

  dispose() {
    this.owner.playData.unRegisterStartMixer(this.preMixer);

    this.instrumentRecord.shader.$removeEvent(this.instrumentShaderEvent);
    this.instrumentRecord.preEffects.$removeEvent(this.instrumentPreEffectsEvent);
    this.instrumentRecord.postEffects.$removeEvent(this.instrumentPostEffectsEvent);
    if (this.postMixer) {
      this.postMixer.dispose()
      this.postMixer = null;
    }
    if (this.preMixer) {
      this.preMixer.dispose()
      this.preMixer = null;
    }
  }
}

// Performs the link between the datamodel and SynthPlayData
class SynthDataController {
  /**
   * 
   * @param {SynthPlayData} playData 
   */
  constructor (playData) {
    this.playData = playData; // SynthPlayData
    /** @type {Record<string,SynthInstrument>} */
    this.instruments = {}
    /** @type {Record<string,SynthInstrument>} */
    this.lastNamesForScope = {}
  }

  getInstrument (instrumentRecord, timeZone, channel) {
    let key = instrumentRecord.name.$v + '_' + timeZone + '_' + channel;
    let instrument = this.instruments[key]
    if (!instrument) {
      instrument = new SynthInstrument(this);
      instrument.loadInstrument(instrumentRecord);
      this.instruments[key] = instrument;
      // console.log(this.instruments)
    }
    // TODO: Find a better way to do this, scope fro multiple instruments? Add extra mixdown for scope?
    this.lastNamesForScope[instrumentRecord.name.$v] = instrument
    return instrument;
  }

  removeInstrument(instrument) {
    for (let kv of Object.entries(this.instruments)) {
      if (kv[1] == instrument) {
        kv[1].dispose();
        delete this.instruments[kv[0]];
        break;
      }
    }
  }

  clear() {
    for (let instrument of Object.values(this.instruments)) {
      instrument.dispose()
    }
    this.instruments = {}
    this.lastNamesForScope = {}
  }

}

export default SynthDataController;

