// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import dataModel from '../../data/dataModel.js';
import { TableBuilder } from '../../../KMN-varstack-browser/components/table-builder.js';
import { Scope } from '../../../KMN-gl-synth-browser/components/webgl/scope.js';
import { addCSS, kmnClassName } from '../../../KMN-varstack-browser/utils/html-utils.js';
import InstrumentDetail from './instrument-detail.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --instrument-detail-height: max(230px, min(320px, 50%));
}
.${kmnClassName}.instrument-list {
  height: calc(100% - var(--instrument-detail-height));
}
.${kmnClassName}.instrument-detail {
  top: calc(100% - var(--instrument-detail-height));
  height: var(--instrument-detail-height);
}
`;

export class InstrumentsPanel extends PanelBase {
  constructor(options) {
    super({}, options);
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement);
    addCSS('instruments-panel', cssStr);

    this.instrumentsListDiv = this.parentElement.$el({ cls: "instrument-list" });
    this.instrumentsDetailDiv = this.parentElement.$el({ cls: "instrument-detail" });

    this.instrumentList = new TableBuilder(
      this.instrumentsListDiv,
      dataModel.instrumentTable,
      {
        fieldNames: ['name', 'shader', 'name'],
        headerNames: ['name', 'shader', 'scope'],
        alternativeBindings: [, , Scope],
        sortOnHeaderClick: true,
        showFilterEdits: true,
        onRowSelect: (rec, ix) => dataModel.currentInstrument.index.$v = ix
      });

    this.instrumentDetailPanel = new InstrumentDetail();
    this.instrumentDetailPanel.initializeDOM(this.instrumentsDetailDiv);
    this.instrumentDetailPanel.show();

  }
}
InstrumentsPanel.getTabName = () => 'INSTRUMENTS';
