// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import { Types } from '../../../KMN-varstack.js/varstack.js';
import { TableBuilder } from '../../../KMN-varstack-browser/components/table-builder.js';
import { addCSS, kmnClassName } from '../../../KMN-varstack-browser/utils/html-utils.js';
import { defaultTextBinding } from '../../../KMN-varstack-browser/utils/inner-text-binding.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --controlsHeaderHeight: 0px;
}
.${kmnClassName}.controlsHeader {
  height: var(--controlsHeaderHeight);
}
.${kmnClassName}.controlsTableDiv {
  top: var(--controlsHeaderHeight);
  height: calc(100% - var(--controlsHeaderHeight));
}
`;

const defaultOptions = {
};
/// <reference path="../../TS/data-model" />
class ControlsEditor extends PanelBase {
  /**
   * @param {{
   *   controlsTable:import("../../../KMN-varstack.js/TS/varstack").ArrayTableVarG<import('../../../../TS/data-model').ControlDefinition>,
   *   onRowClick?: (rec: import('../../../../TS/data-model').ControlDefinition, ix: number) => void
   * }} options
   */
  constructor(options) {
    super(defaultOptions, options);

    this.searchStr = new Types.String();
    this.searchStr.$addEvent( (x) => this.filterShaders(x.$v) );

    this.controlsTable = options.controlsTable;
    this.searchTableEl = null;
  }

  filterShaders(value) {
    let reg = new RegExp(value,'i');
    // TODO: search the right fields
    // this.searchTableEl.setFilter((ix, rec) => reg.test(rec.name.$v) );
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement);
    addCSS('control-editor',cssStr);

    this.controlsHeaderDiv = this.parentElement.$el({ cls: "controlsHeader" });
    this.controlsTableDiv = this.parentElement.$el({ cls: "controlsTableDiv" });

    // this.searchInputBuilder = new InputBuilder(this.controlsHeaderDiv);
    // this.searchInput = this.searchInputBuilder.addVar(this.searchStr,'SEARCH');

    this.searchTableEl = new TableBuilder(
      this.controlsTableDiv,
      this.controlsTable,
      {
        fieldNames: [
          'name',
          'definition',
          'defaultLevel',
          'defaultLevel',
          'midiControlNr',
          'midiControlName'],
        alternativeBindings: [
          ,
          ,
          ,
          defaultTextBinding,
          ,

        ],
        // alternativeBindings: {'controlNames.name': defaultTextBinding},
        inlineEdit: true,
        editList: true,
        onRowClick: (rec, ix) => {
          if (this.options.onRowClick) {
            this.options.onRowClick(rec, ix);
          }
        }
      });
  }
}
ControlsEditor.getTabName = () => 'CONTROLS';

export default ControlsEditor;
