// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import dataModel from '../../data/dataModel.js';
import { TableBuilder } from '../../../KMN-varstack-browser/components/table-builder.js';
import InputBuilder from '../../../KMN-varstack-browser/components/input-builder.js';
import TablePanel from '../../../KMN-varstack-browser/panels/table-panel.js';
import { addCSS, kmnClassName } from '../../../KMN-varstack-browser/utils/html-utils.js';
import PopUp from '../../../KMN-utils-browser/components/pop-up.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --effectsHeaderHeight: 24px;
  --instrumentDetailHeight: calc(92px + var(--effectsHeaderHeight));
  --effectsHeight: calc(100% - var(--instrumentDetailHeight));
}
.${kmnClassName}.instrument-details-input {
  height: var(--instrumentDetailHeight);
}
.${kmnClassName}.instrument-settings-header {
  background: var(--tableHeaderBackground);
  text-align: center;
  line-height: var(--effectsHeaderHeight);
  height: var(--effectsHeaderHeight);
  border: 1px solid black;
}
.${kmnClassName}.instrumentSettings {
  background: var(--tableBackground);
  top: var(--effectsHeaderHeight);
}
.${kmnClassName}.effectArea {
  width: 100%;
  top: var(--instrumentDetailHeight);
  height: var(--effectsHeight);
}
.${kmnClassName}.preEffectHeader {
  left: 0;
  width: 50%;
  background: var(--tableHeaderBackground);
  text-align: center;
  height: var(--effectsHeaderHeight);
  border: 1px solid black;
  line-height: var(--effectsHeaderHeight);
}
.${kmnClassName}.postEffectHeader {
  right: 0;
  width: 50%;
  background: var(--tableHeaderBackground);
  text-align: center;
  height: var(--effectsHeaderHeight);
  border: 1px solid black;
  line-height: var(--effectsHeaderHeight);
}
.${kmnClassName}.preEffectArea {
  left: 0;
  top: var(--effectsHeaderHeight);
  width: 50%;
  height: calc(100% - var(--effectsHeaderHeight));
}
.${kmnClassName}.postEffectArea {
  right: 0;
  top: var(--effectsHeaderHeight);
  width: 50%;
  height: calc(100% - var(--effectsHeaderHeight));
}
.${kmnClassName}.instrumentSettings .${kmnClassName}.isLabel {
  width: 120px;
}
/*!css*/`

const defaultOptions = {
};

const effectShadersTab = 'EFFECT-SHADERS'
class InstrumentDetail extends PanelBase {
  constructor(options) {
    super(defaultOptions, options);
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement);
    addCSS('instrument-detail', cssStr);

    this.instrumentDetailsInput = this.parentElement.$el({    cls: "instrument-details-input"       });
    this.instrumentSettingsHeader = this.instrumentDetailsInput.$el({ cls: "instrument-settings-header" });
    this.instrumentSettings = this.instrumentDetailsInput.$el({ cls: "instrumentSettings" });
    this.instrumentSettingsHeader.innerText = 'Instrument settings';

    this.effectArea = this.parentElement.$el({  cls: "effectArea"     });
      this.preEffectArea = this.effectArea.$el({  cls: "preEffectArea"     });
      this.postEffectArea = this.effectArea.$el({  cls: "postEffectArea"     });

      this.preEffectHeader = this.effectArea.$el({  cls: "preEffectHeader"     });
      this.postEffectHeader = this.effectArea.$el({  cls: "postEffectHeader"     });
    this.preEffectHeader.innerText = 'pre mixing effects'
    this.postEffectHeader.innerText = 'post mixing effects'


    this.currentEffectTable = undefined;
    this.preEffectTableEl = new TableBuilder(
      this.preEffectArea,
      dataModel.currentInstrument.cursor.$v.preEffects,
      {
        editList: true,
        onRowClick: (rec) => {
          // TODO: Make this work by lookup
          dataModel.currentShader.table = dataModel.effectShaders;
          dataModel.currentShader.index.$v = dataModel.effectShaders.findIx(
            'name', rec.$v.shader.$v);
        },
        addClick: () => {
          // this.tabController.setSelectedTab(effectShadersTab);
          this.currentEffectTable = this.preEffectTableEl.table;
          this.effectsPopup.show('Select a shader to add to the pre-effects');
        }
      });

    this.postEffectTableEl = new TableBuilder(
      this.postEffectArea,
      dataModel.currentInstrument.cursor.$v.postEffects,
      {
        editList: true,
        onRowClick: (rec) => {
          // TODO: Make this work by lookup
          dataModel.currentShader.table = dataModel.effectShaders;
          dataModel.currentShader.index.$v = dataModel.effectShaders.findIx(
            'name', rec.$v.shader.$v);
        },
        addClick: () => {
          this.currentEffectTable = this.postEffectTableEl.table;
          // this.tabController.setSelectedTab(effectShadersTab);
          this.effectsPopup.show('Select a shader to add to the post-effects');
        }
      });

    const setInputAsCurrent = () => {
      dataModel.currentShader.table = dataModel.inputShaders;
      dataModel.currentShader.index.$v = dataModel.inputShaders.findIx(
        'name',dataModel.currentInstrument.cursor.$v.shader.$v);
    }


    this.instrumentSettingsBuilder = new InputBuilder(this.instrumentSettings, {
      onLabelClick: (event, labelName) => {
        event.preventDefault();

        if (labelName === 'shader') {
          setInputAsCurrent();
        }
      }
    });

    {
      const ib = this.instrumentSettingsBuilder;
      ib.addVar( dataModel.currentInstrument.cursor.$v.name   );
      ib.addVar( dataModel.currentInstrument.cursor.$v.shader );
      ib.addVar( dataModel.currentInstrument.cursor.$v.preset );
    };

    // this.tabController.addTab(TableSearch,
    this.effectsPopup = PopUp.Create(TablePanel,
      {
        tabName: effectShadersTab,
        searchTable: dataModel.effectShaders,
        fieldNames: ['name'],
        onRowClick: (rec, ix) => {
          dataModel.currentShader.table = dataModel.effectShaders;
          dataModel.currentShader.index.$v = ix;

          this.currentEffectTable.add({ shader: rec.name.$v, preset: '' });
          this.effectsPopup.hide();
        }
      });
  }
}
InstrumentDetail.getTabName = () => 'INSTRUMENT-DETAIL';

export default InstrumentDetail;
