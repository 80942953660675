// Copyright by André van Kammen
// Licensed under CC BY-NC-SA
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import { otherControls } from '../../KMN-gl-synth.js/otherControls.js';
import PanZoomControl from '../../KMN-utils-browser/pan-zoom-control.js';
import getWebGLContext from '../../KMN-utils.js/webglutils.js';
import { RenderControl } from '../../KMN-varstack-browser/components/webgl/render-control.js';

const defaultOptions = {
  maxNoteLength: 30,
  defaultNoteLength: 30.1,
};

const glsl = (x) => x[0]; // makes the glsl-literal VS Code extention work
function getVertexShader() {
  return glsl`
    uniform vec2 canvasResolution;

    uniform vec2 scale;
    uniform vec2 position;
    uniform sampler2D trackDataTexture;
    uniform float trackNr;

    out float value;
    out vec2 px;
    out vec2 localCoord;
    out float localRadius;
    out float noteLengthInPixels;
    out float note;

    void main(void) {
      int trackDataIx = gl_VertexID / 6;
      // We use vertex pulling to get the data that is the same for 6 points
      vec4 trackData = texelFetch(trackDataTexture, ivec2(trackDataIx % 1024, trackDataIx / 1024), 0);

      value = trackData.w;
      note  = trackData.y;

      // Calculate our center position
      vec2 pos = trackData.xy;
      pos.y = pos.y / 63.5;
      pos = (pos.xy - position) * scale - 1.0;

      // calculate the margin in absolute pixel size so the note margin size
      // is the same whatever the scale is
      px = 2.0 / canvasResolution.xy;
      vec2 margin = px * 9.0;
      if (value >= 1.0) {
        margin += px;
      }

      // Round to nearest pixel
      // pos -= mod(pos, px) + px * 0.5;

      noteLengthInPixels = max(trackData.z * scale.x, px.y * 2.0);

      int pointIx = gl_VertexID % 6;
      // This is the only value messing up the bits for 2 triangles making a square so we change that
      if (pointIx==4) {
        pointIx = 2;
      }

      // Calculate X and Y from the index number we get
      localCoord.x = ((pointIx & 1) == 1) ? noteLengthInPixels + margin.x : -margin.x;
      localCoord.y = ((pointIx & 2) == 0) ? margin.y : -margin.y;

      // Round to nearest pixel
      // localCoord -= mod(localCoord, px) + px * 0.5;

      pos.x += localCoord.x;
      pos.y += localCoord.y;

      // TODO this is asuming that width > height
      localRadius = margin.y;

      // Round to nearest pixel
      // pos -= mod(pos, px) + px * 0.5 ;
      pos.y += px.y * trackNr * 2.0;
      gl_Position = vec4(pos, 1.0, 1.0);
    }`;
}

// The shader that calculates the pixel values for the filled triangles

function getFragmentShader() {
  return glsl`
  precision highp float;

  uniform int drawCount;
  uniform float trackNr;

  in float note;
  in float value;
  in vec2 localCoord;
  in vec2 px;
  in float localRadius;
  in float noteLengthInPixels;

  out vec4 fragColor;
  vec3 colors[12] = vec3[12](
    vec3(1.0,   0,   0),
    vec3(  0, 0.7,   0),
    vec3(  0,   0, 1.0),
    vec3(0.8, 0.5,   0),
    vec3(  0, 0.5, 0.8),
    vec3(0.8,   0, 0.8),
    vec3(0.9, 0.5,   0),
    vec3(  0, 0.5, 0.5),
    vec3(0.5,   0, 0.9),
    vec3(0.5, 0.5,   0),
    vec3(  0, 0.25,1.0),
    vec3(0.5,   0, 1.0)
  );

  vec3 getColor(int ix) {
    return colors[ix % 12];
  }

  float line(vec2 p)
  {
    vec2 scale = vec2(px.y / px.x, 1.0);
    float d = 10000.0;
    if (p.x > 0.0 && p.x < noteLengthInPixels) {
      // Inner line distance:
      //   the absolute value of y, offset with 2 pixels
      d = abs(p.y) + px.y * 4.0;

    }
    if (value<1.0) {
      return d;
    }
    return min(d,
               min(length(p * scale),
                   length((p - vec2(noteLengthInPixels, 0.0)) *
                               scale)));
  }

  void main(void) {
    vec3 tc = getColor(int(trackNr)) * (0.5 + 0.5 * localCoord.x / noteLengthInPixels);//  * float((localCoord.x - localRadius * 0.5 > 0.0) && (localCoord.x < noteLengthInPixels - localRadius * 0.5));
    if (value>=1.0) {
      // Draw animated if selected
      tc += -0.3 + 0.2 * sin(float(drawCount) * 0.2 + localCoord.x * 5.0 * (1.0 + pow(2.0,note/12.0))) * sign(localCoord.y);
    }

    float d = line(localCoord);
    fragColor = vec4(
      clamp(tc + (0.8 + value) * smoothstep(localRadius * 0.5, localRadius, d)
           , 0.0, 1.0),
      (1.0 - smoothstep(localRadius * 0.75, localRadius, d)) );
  }`;
}

class TrackTimeline {
  textureInfo = { texture: undefined, size: 0, bufferWidth: 1024 };
  trackInfo = [];
  constructor(owner, options) {
    this.options = { ...defaultOptions, ...options };

    this.clearAll();
    this.renderBound = this.render.bind(this);
    this.render();
    this.fullScreen = undefined;

    this.playPos = 0;
    this.avgDuration = 2500.0;
    this.upperNote = 127.0;
    this.lowerNote = 0.0;

    this.rc = RenderControl.geInstance();
  }

  clearAll() {
    this.control?.clear();

    this.autoTracking = true;
    this.autoScaleX = true;
    this.autoScaleY = true;

    this.invalidateCount = 0;
    this.drawCount = 0;

    // this.attrBuffer = null;
    this.attributeBuffer = null;
  }

  /**
   * @param {HTMLElement} parentElement
   * @param {HTMLCanvasElement} canvas
   */
  initializeDOM(parentElement, canvas) {
    this.parentElement = parentElement;
    this.canvas = canvas || this.parentElement.$el({
      tag: 'canvas',
      cls: 'timelineCanvas',
    });
    this.gl = getWebGLContext(this.canvas, { alpha: true, desynchronized:true });
    this.shader = this.gl.getShaderProgram(
      getVertexShader(),
      getFragmentShader(),
      2
    );

    this.parentElement.ondblclick = () => {

      // this.fullScreen = !this.fullScreen;
      this.autoTracking = true;
      this.autoScaleX = true;
      this.autoScaleY = true;

      // if (this.canvas.classList.contains('fullscreen')) {
      //   this.canvas.classList.remove('fullscreen');
      // } else {
      //   this.canvas.classList.add('fullscreen');
      // }
    }

    this.control = new PanZoomControl(this.parentElement, {
      onChange: () => {
        this.autoTracking = false;
        this.autoScaleX = false;
        this.autoScaleY = false;

      },
      minYScale: 1.0,
      maxYScale: 10.0,
      minXScale: 0.01,
      maxXScale: 10.0,

      minXPos: -1.0,
      maxXPos: 100.0,
      minYPos: 0.0,
      maxYPos: 1.0,

      includeSizeInMaxPos: true
    });

    const ext = this.gl.getExtension('EXT_color_buffer_float');
    // const linear =  this.gl.getExtension('OES_texture_float_linear');

    this.invalidateCount = 0;
    this.drawCount = 0;
    this.render();
  }

  setEntryPlaying(entry, isPlaying) {
    setTimeout( () => {
      let ix = entry.timelineIx;
      let a1 = entry.timelineArray;
      let v = isPlaying ? 1.0 + entry.velocity : 0.0;
      // Calculate auto zoom data
      if (isPlaying) {
        this.playPos = entry.startTime;
        if (entry.duration < 4.0 * this.avgDuration) {
          this.avgDuration = this.avgDuration * 0.99 + 0.01 * (entry.duration || 0.001)
        }
        this.lowerNote = this.lowerNote * 0.995 + 0.005 * 127.0;
        this.upperNote = this.upperNote * 0.995 + 0.005 * 1.0;
        this.lowerNote = Math.min(this.lowerNote, entry.note);
        this.upperNote = Math.max(this.upperNote, entry.note);
        // console.log(entry);
      }
      // console.log(entry.startTime, ix, a1[ix] * 20000);
      a1[ix + 3] = v;
    }, 40); // TODO calculate delay for sync or make adjustable
  }

  changeEntryControl(entry, time, timeZone, controlType, value) {
    if (controlType === otherControls.pitch) {
      setTimeout(() => {
        let ix = entry.timelineIx;
        let a1 = entry.timelineArray;
        // TODO get pitch range for entry channel?
        let v = entry.note + value * 2.0;
        a1[ix + 1] = v;
      }, 40); // TODO calculate delay for sync or make adjustable
    }
  }

  updateCanvas() {
    let gl = this.gl;
    let shader = this.shader;

    if (gl && shader) {
      let {w, h, dpr} = this.rc.updateCanvasSize();

      let rect = this.parentElement.getBoundingClientRect();
      if (!this.fullScreen) {
        // Tell WebGL how to convert from clip space to pixels
        gl.viewport(rect.x * dpr, h - (rect.y + rect.height) * dpr, rect.width * dpr, rect.height * dpr);
        w = rect.width * dpr;
        h = rect.height * dpr;
      }
      else {
        gl.viewport(0, 0, w, h);
      }
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
      // gl.clear(gl.COLOR_BUFFER_BIT);
      gl.enable(this.gl.BLEND);
      gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);

      // gl.lineWidth(3);
      gl.useProgram(shader);

      if (this.attributeBuffer && this.attributeBuffer.length) {
        // this.attrBuffer = gl.updateOrCreateFloatArray(
        //   this.attrBuffer,
        //   this.attributeBuffer
        // );

        if (shader.u.trackDataTexture) {
          gl.activeTexture(gl.TEXTURE2);
          // TODO only partial update of changed area
          this.textureInfo = gl.createOrUpdateFloat32TextureBuffer(this.attributeBuffer, this.textureInfo);
          gl.bindTexture(gl.TEXTURE_2D, this.textureInfo.texture);
          gl.uniform1i(shader.u.trackDataTexture, 2);
          gl.activeTexture(gl.TEXTURE0);
        }

        // shader.u.scale.set(3.0 + 2.8*Math.sin(this.drawCount/180.0),1.0);
        // shader.u.pos.set(12.0 + 12.0 * Math.sin(this.drawCount/1600.0),0.0);

        if (this.autoScaleX) {
          this.control.xScale = this.control.xScale*0.99 + 0.01 * 1500.0 / (100 + this.avgDuration);
        }
        if (this.autoScaleY) {
          let newScale = 63.5 / (this.upperNote-this.lowerNote);
          if (this.control.yScale > newScale) {
            this.control.yScale = this.control.yScale*0.97 + 0.03 * newScale;
          } else {
            this.control.yScale = this.control.yScale*0.996 + 0.004 * newScale;
          }
        }

        if (this.autoTracking) {
          this.control.xOffset = this.control.xOffset * 0.988 + 0.012 * (this.playPos/40000.0 - 0.5/this.control.xScale);
          this.control.yOffset = this.control.yOffset * 0.996 + 0.004 * Math.max((this.lowerNote / 127.0) -0.1, 0.0);
        }

        shader.u.canvasResolution && shader.u.canvasResolution.set(w, h);
        shader.u.scale.set(this.control.xScale,
                           this.control.yScale);
        shader.u.position.set(this.control.xOffset * 2.0,
                              this.control.yOffset * 2.0);
        shader.u.drawCount && shader.u.drawCount.set(this.drawCount);

        for (let trackIx = 0; trackIx<this.trackInfo.length; trackIx++) {
          shader.u.trackNr.set(this.trackInfo[trackIx].channel);
          // shader.u.trackNr.set(trackIx);
          if (this.trackInfo[trackIx].length > 0) {
            gl.drawArrays(gl.TRIANGLES, this.trackInfo[trackIx].start * 6, this.trackInfo[trackIx].length * 6);
          }
        }

      }
    }
    this.invalidate();
  }

  invalidate() {
    this.invalidateCount++;
  }

  render() {
    if (this.invalidateCount !== this.drawCount) {
      this.drawCount = this.invalidateCount;
      this.updateCanvas();
    }
    this.update = globalThis.requestAnimationFrame(this.renderBound);
  }

  getShaderSource () {
    return getFragmentShader();
  }

  compileShader = (name, source, options) => {
    console.log('RectControler compile: ',name,options);
    let compileInfo = this.gl.getCompileInfo( source,
      this.gl.FRAGMENT_SHADER,
      2
    );
    if (compileInfo.compileStatus) {
      this.shader = this.gl.getShaderProgram(
        getVertexShader(),
        source,
        2
      );
    } else {
      console.log('Shader error: ',compileInfo);
    }
    return compileInfo;
  }

  loadTrackData(tracks = this.tracks) {
    this.clearAll();
    this.tracks = tracks;

    this.lineCount = 0;
    for (let track of Object.values(this.tracks)) {
      this.lineCount += track.entries.length;
    }

    let size = this.lineCount * 16;

    // Round up to a mutiple of lineSize
    const lineSize = 1024 * 4;
    size = Math.ceil(size / lineSize) * lineSize;
    let a1 = (this.attributeBuffer = new Float32Array(size));

    let lIx = 0;
    this.trackInfo = [];
    let pointIx = 0;
    for (let track of Object.values(this.tracks)) {
      let entryCount = 0;
      for (let ix = 0; ix < track.entries.length; ix++) {
        const entry = track.entries[ix];
        const width = Math.max(entry.duration || 0.0, 1.0) / 20000;
        const left = entry.startTime / 20000;
        const value = 0.0; // Math.round((entry.velocity || entry.value) * 1023);

        if (entry.note) {
          // console.log(entry.note, entry);
          if (!isFinite(left) || !isFinite(width) || !isFinite(entry.note)) {
            debugger;
          }
          entry.timelineArray = a1;
          entry.timelineIx = lIx; //pointIx * 4;
          entry.onSetPlaying = this.setEntryPlaying.bind(this, entry);
          entry.onController = this.changeEntryControl.bind(this, entry);

          // The information for vertex pulling
          a1[lIx++] = left;
          a1[lIx++] = entry.note;
          a1[lIx++] = width; // trackCount;
          a1[lIx++] = value;
          entryCount++;
        }
      }
      this.trackInfo.push({
        channel: track.channelNr,
        start: pointIx,
        length: entryCount
      });
      pointIx += entryCount;
    }

    this.invalidate();
  }
}

export default TrackTimeline;
