// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import { animationFrame } from '../../KMN-utils-browser/animation-frame.js';
import PanelBase from '../../KMN-utils-browser/components/panel-base.js';
import InputBuilder from '../../KMN-varstack-browser/components/input-builder.js';
import { RenderControl } from '../../KMN-varstack-browser/components/webgl/render-control.js';
import { GLTextComponent } from '../../KMN-varstack-browser/components/webgl/render-text.js';
import { HorizontalSliderElement, VerticalSliderElement } from '../../KMN-varstack-browser/components/webgl/sliders.js';
import { addCSS, kmnClassName } from '../../KMN-varstack-browser/utils/html-utils.js';
import { RecordVar } from '../../KMN-varstack.js/structures/record.js';
import { BaseVar } from '../../KMN-varstack.js/vars/base.js';
import { FloatVar } from '../../KMN-varstack.js/vars/float.js';
import dataModel from '../data/dataModel.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --testSettingsAreaWidth: 640px;
}

.${kmnClassName}.ui-shader-detail .ui-settings-area {
  width: var(--testSettingsAreaWidth);
  border-right: var(--borderWidth) solid var(--borderColor);
}

.${kmnClassName}.ui-shader-detail .ui-shader-area {
  left: var(--testSettingsAreaWidth);
  width: calc(100% - var(--testSettingsAreaWidth));
  border-left: var(--borderWidth) solid var(--borderColor);
}

.${kmnClassName}.ui-shader-detail .ui-shader-element {
  background: black;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 120px;
  height: 120px;
}
.${kmnClassName}.ui-shader-detail .text-test {
  left: 24px;
  top: 24px;
  height: 24px;
}
.${kmnClassName}.ui-shader-detail .text-test-original {
  left: 24px;
  top: 48px;
  height: 24px;
};
  `;

const defaultOptions = {
};

class UIShaderTestArea extends PanelBase {
  constructor(options) {
    super(defaultOptions, options);
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    addCSS('ui-shader-test-area', cssStr);

    super.initializeDOM(parentElement);

    this.renderControl = RenderControl.geInstance();

    this.parentElement.classList.add('ui-shader-detail');

    this.settingsAreaDiv = this.parentElement.$el({ cls: "ui-settings-area" });
    this.shaderAreaDiv = this.parentElement.$el({ tag:'div', cls: "ui-shader-area" });

    this.shaderElementDiv = this.shaderAreaDiv.$el({ tag: 'div', cls: "ui-shader-element" });

    const setElementSize = (size) => {
      this.shaderElementDiv.style.width = size.width.toFixed(2) + 'px';
      this.shaderElementDiv.style.height = size.height.toFixed(2) + 'px';
    }

    // let testStr = 'Hello WebGL Text!';
    // this.textTest = this.shaderAreaDiv.$el({ cls: 'text-test' });
    // new GLTextComponent(this.textTest, testStr);
    // this.textTestOriginal = this.shaderAreaDiv.$el({ cls: 'text-test-original' });
    // this.textTestOriginal.$setTextNode(testStr)

    this.valueVar = new FloatVar();
    this.testElement = null; // new VerticalSliderElement(this.valueVar, this.shaderElementDiv);
    dataModel.currentUIShader.cursor.$v.shaderName.$addDeferedEvent(() => {
      if (this.testElement) {
        this.testElement.dispose();
      }
      const TestClass = this.renderControl.registeredShaders[dataModel.currentUIShader.cursor.$v.shaderName.$v].demoClass;
      this.testElement = new TestClass(this.shaderElementDiv);
      // @ts-ignore: It doesn't see the static methods on the baseclass
      setElementSize(TestClass.preferredSize);

      if (this.demoInputBuilder) {
        this.demoInputBuilder.dispose();
      }
      if (this.testElement.demoData) {
        this.demoInputBuilder = new InputBuilder(this.settingsAreaDiv, { showValues: true });
        if (this.testElement.demoData instanceof RecordVar) {
          //  whole set of parameters as a record
          this.demoInputBuilder.addRecord(this.testElement.demoData);
        } else {
          // @ts-ignore Shut up stupid error if i remove the else it's OK
          if (this.testElement.demoData instanceof BaseVar) { // single var
            this.demoInputBuilder.addVar(this.testElement.demoData,'x');
          } else {
            // For now object with key/val is the other possibility
            for (let [key, value] of Object.entries(this.testElement.demoData)) {
              this.demoInputBuilder.addVar(value, key);
            }
          }
        }
      }

      // this.slider._componentInfo
    });

    let mouseIsDown = false;
    this.shaderAreaDiv.onmousedown = () => {
      mouseIsDown = true;
    }
    this.shaderAreaDiv.onmouseup = () => {
      mouseIsDown = false;
    }
    this.shaderAreaDiv.onmousemove = (evt) => {
      if (evt.target === this.shaderElementDiv) {
        return;
      }
      if (mouseIsDown) {
        let rect = this.shaderAreaDiv.getBoundingClientRect();
        let centerX = rect.x + rect.width * 0.5;
        let centerY = rect.y + rect.height * 0.5;
        setElementSize({
          width: Math.max(16, (Math.abs(evt.clientX - centerX)) * 2),
          height: Math.max(16, (Math.abs(evt.clientY - centerY)) * 2)
        });
      }
    }
    // animationFrame(this.handleFrame.bind(this));
      
    // this.testArea = this.parentElement.$el({ cls: 'test-area' });
  }
  // handleFrame() { 
  //   let angle = performance.now() / 1000.0;
  //   let transfromStr = 'translate3D(' + (Math.sin(angle)*5).toFixed(2) + 'px, ' + (Math.cos(angle)*5).toFixed(2) + 'px, 0)';
  //   this.textTest.style.transform = transfromStr;
  //   this.textTestOriginal.style.transform = transfromStr;
  //   animationFrame(this.handleFrame.bind(this));
  // }
}
UIShaderTestArea.getTabName = () => 'UI-SHADERS-TEST';

export default UIShaderTestArea;


// TODO More consistancy in naming the parts of the software
// Area = Panel --contraints-> Tile --contraints-> Components

// Area = a panel singleton so a area in the software todo something
// Panel = a panel that can be re-used
// Tile = a special purpose component tableTile / inputTile / codeTile / trackTile / mixeditor

// TabPanel a pannel containing tabs that contain other panels
// Standardize tiles for shaderuse, register in rectcontroller
// Standard for nested PanzoomControl, one master panzoomcontrol for the whole app, rename to inputcontrol
// panzoomcontrol gets rectangles from rectcontroller
// RectControler should get it's rects from a lyaoutcontroller that can link to HTML or standalone (full shader renderer)
// areas can be added to globalcontroller for use in the subcontrollers (editController, codeEditController, playController etc) 
//                         should expose actionVars with names and contain state like cursors etc. for instances
// inputs (keyboard, mouse, pen, midi etc) can be linked to actionVars