// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import PanelBase from '../../../KMN-utils-browser/components/panel-base.js';
import { addCSS, kmnClassName } from '../../../KMN-varstack-browser/utils/html-utils.js';
import TrackEditor from '../../arrangement/arrangement-editor.js';
import SoftwareKeyBoard from '../../software-keyboard.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --keyboardHeight: calc(5.625vw);
}

.${kmnClassName}.tracks-panel {
  background: var(--backgroundColor);
  border: var(--borderWidth) solid var(--borderColor);
  width: calc(100% - var(--borderWidth2));
  height: calc(100% - var(--keyboardHeight) - var(--borderWidth2));
  bottom: var(--keyboarHeight);
}

.${kmnClassName}.keyboard-panel {
  background: var(--backgroundColor);
  border: var(--borderWidth) solid var(--borderColor);
  bottom: 0px;
  width: calc(100%);
  height: calc(var(--keyboardHeight));
  user-select: none;
  overflow: hidden;
}
`

const defaultOptions = {
};

export class ArangementPanel extends PanelBase {
  constructor(options) {
    super(defaultOptions, options);
    this.canvas = options.canvas;
    this.trackEditor = new TrackEditor();
    this.softwareKeyboard = undefined;
    this.softwareKeyboard = new SoftwareKeyBoard();

  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    super.initializeDOM(parentElement);
    addCSS('arrangement-panel', cssStr);

    this.tracksPanel = this.parentElement.$el({cls:'tracks-panel'});
    this.keyboardPanel = this.parentElement.$el({cls:'keyboard-panel'});

    this.trackEditor.initializeDOM(this.tracksPanel, this.canvas);
    this.softwareKeyboard.initializeDOM(this.keyboardPanel);
  }
}
ArangementPanel.getTabName = () => 'ARRANGEMENT';
