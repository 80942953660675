// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

// From https://static.roland.com/assets/media/pdf/FP-30_MIDI_Imple_e01_W.pdf
// NRPN Data entry
// MSB LSB MSB Description
// 01H 08H mmH Vibrato Rate(relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 01H 09H mmH Vibrato Depth(relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 01H 0AH mmH Vibrato Delay(relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 01H 20H mmH TVF Cutoff Frequency(relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 01H 21H mmH TVF Resonance(relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 01H 63H mmH TVF & TVA Envelope Attack Time
//             (relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 01H 64H mmH TVF & TVA Envelope Decay Time
//             (relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 01H 66H mmH TVF & TVA Envelope Release Time
//             (relative change)
//         mm: 0EH–40H–72H(-50–0–+50)
// 18H rrH mmH Drum Instrument Pitch Coarse
//             (relative change)
//         rr: key number of drum instrument
//         mm: 00H–40H–7FH(-63–0–+63 semitone)
// 1AH rrH mmH Drum Instrument TVA Level
//             (absolute change)
//         rr: key number of drum instrument
//         mm: 00H–7FH(zero - maximum)
// 1CH rrH mmH Drum Instrument Panpot
//             (absolute change)
//         rr: key number of drum instrument
//         mm: 00H, 01H–40H–7FH
//             (Random, Left–Center–Right)
// 1DH rrH mmH Drum Instrument Reverb Send Level
//             (absolute change)
//         rr: key number of drum instrument
//         mm: 01H–7FH(zero - maximum)
// 1EH rrH mmH Drum Instrument Chorus Send Level
//             (absolute change)
//         rr: key number of drum instrument
//         mm: 01H–7FH(zero - maximum)

import { instruments, getGroupName } from '../data/midi-instrument-list.js';
import { otherControls } from '../../KMN-gl-synth.js/otherControls.js';

class TrackEntry {
  constructor(track, startTime) {
    this.startTime = startTime;
    this.element = undefined
    this.playNote = undefined
    this.track = track;
    this.onController = undefined;
    this.onSetPlaying = undefined;
  }
      
  changeControl(time, controlType, value) {
    // if (this.playNote) {
    //   this.playNote.changeControl(time, 'midi-file', controlType, value);
    // }
    if (this.onController) {
      this.onController(time, 'midi-file', controlType, value);
    }
  }

  setPlaying (playNote) {
    this.playNote = playNote 
    if (this.onSetPlaying) {
      this.onSetPlaying(playNote !== undefined);
    }
  }
}

class TrackNote extends TrackEntry {
  constructor(track, startTime, note, duration, velocity) {
    super(track, startTime);

    this.note = note;
    this.duration = duration;
    this.velocity = velocity;
    this.releaseVelocity = 0;

    // Used for state while playing/seeking
    this.readyForRelease = false;
    this.endTimer = 0;
    this.endTime = 0;
  }

  updateDuration(duration, releaseVelocity) {
    this.duration = duration;
    this.releaseVelocity = releaseVelocity;
  }
}

class TrackControl extends TrackEntry {
  constructor(track, startTime, controlType, value) {
    super(track, startTime);

    this.controlType = Number.parseInt(controlType);
    this.value = Number.parseFloat(value);
  }
}

class TrackTempo extends TrackEntry {
  constructor(track, startTime, microsecondsPerBeat) {
    super(track, startTime);

    this.microsecondsPerBeat = microsecondsPerBeat;
  }
}

class TrackTimeSignature extends TrackEntry {
  constructor(track, startTime, numerator, denominator, metronome, thirtyTwoSeconds) {
    super(track, startTime);

    this.numerator = numerator;
    this.denominator = denominator;
    this.metronome = metronome;
    this.thirtyTwoSeconds = thirtyTwoSeconds;
  }
}

class TrackInstrument extends TrackEntry {
  constructor(track, startTime, instrument) {
    super(track, startTime);

    this.instrument = instrument;
  }
}

class TrackData {
  constructor(owner, trackNr, channelNr) {
    this.owner = owner;
    this.trackNr = trackNr;
    this.channelNr = channelNr;
    this.instrument = null;
    /** @type {TrackEntry[]} */
    this.entries = [];
    this.name = '';
    this.resetRPN();
    this.playStatus = {
    }
  }

  resetRPN() {
    // This whole (N)RPN is implemented in such an overcomplicated way, i guess because of historic reasons
    this.NRPN_High = -1;
    this.NRPN_Low = -1;
    this.RPN_High = -1;
    this.RPN_Low = -1;
    this.RPN_Data_Low = 0;
    this.RPN_Data_High = 0;
    this.RPN_coarseTune = 0;
    this.RPN_fineTune = 0;
    this.RPNActive = false;
    this.NRPNActive = false;
  }

  getUniqueChannelNr() {
    return this.trackNr * 16 + this.channelNr;
  }

  getKey() {
    return TrackData.getKey(this.trackNr, this.channelNr);
  }

  setName(name) {
    this.name = name;
  }

  addNote(startTime, note, duration, velocity) {
    let trackNote = new TrackNote(this, startTime, note, duration, velocity);
    this.entries.push(trackNote);
    return trackNote;
  }

  addControl(startTime, controlType, value) {
    let trackControl = new TrackControl(this, startTime, controlType, value);
    this.entries.push(trackControl);
  }

  addTempo(startTime, microsecondsPerBeat) {
    let trackControl = new TrackTempo(this, startTime, microsecondsPerBeat);
    this.entries.push(trackControl);
  }

  addInstrument(startTime, instrument) {
    let trackInstrument = new TrackInstrument(this, startTime, instrument);
    if (this.instrument !== instrument) {
      this.instrument = instrument;
      this.entries.push(trackInstrument);
    }
  }

  addTimeSignature(startTime, numerator, denominator, metronome, thirtyTwoSeconds) {
    let trackControl = new TrackTimeSignature(this, startTime, numerator, denominator, metronome, thirtyTwoSeconds);
    this.entries.push(trackControl);
  }

  // Function for handeling RPN and also NRPN statefull sequences in controls
  setRPN(time, controlNr, value) {
    // console.log('(N)RPN ',controlNr, ',', value);
    // Implemented with information from: http://www.philrees.co.uk/nrpnq.htm
    const updateRPNVal = () => {
      // This is very messy to implment since high/low data is interpreted
      // differently based on what to set and then even fine and coarse split up in here :(
      if (this.RPNActive) {
        if ((this.RPN_High === 127) && (this.RPN_Low === 127)) {
          this.resetRPN();

        } else if ((this.RPN_High === 0) && (this.RPN_Low === 0)) {

          const value = this.RPN_Data_High + (this.RPN_Data_Low / 100);
          this.addControl(time, otherControls.pitchRange, value);
          console.log('pitch range changed: ', value);

        } else if ((this.RPN_High === 0) && (this.RPN_Low === 1)) {

          this.RPN_fineTune = (this.RPN_Data_High << 7 + this.RPN_Data_Low) * 100 / 8192;
          const value = this.RPN_courseTune + this.RPN_fineTune;
          this.addControl(time, otherControls.tuning, value);
          console.log('fine tuning changed: ', value);

        } else if ((this.RPN_High === 0) && (this.RPN_Low === 2)) {

          this.RPN_courseTune = this.RPN_Data_High;
          const value = this.RPN_courseTune + this.RPN_fineTune;
          this.addControl(time, otherControls.tuning, value);
          console.log('coarse tuning changed: ', value);

        }else if ((this.RPN_High === 0) && (this.RPN_Low === 3)) {

          // TODO: find out if this is right (if necessary), because spec was missisng in source
          // this is based on my assumption being the same as fine tuning
          const value = (this.RPN_Data_High << 7 + this.RPN_Data_Low) * 100 / 8192;
          this.addControl(time, otherControls.tuningProgram, value);
          console.log('program tuning changed: ', value);

        } else if ((this.RPN_High === 0) && (this.RPN_Low === 4)) {

          // TODO: find out if this is right (if necessary), because spec was missisng in source
          // this is based on my assumption being the same as fine tuning
          const value = (this.RPN_Data_High << 7 + this.RPN_Data_Low) * 100 / 8192;
          this.addControl(time, otherControls.tuningBank, value);
          console.log('bank tuning changed: ', value);

        } else if ((this.RPN_High === 0) && (this.RPN_Low === 5)) {
          // Modulation Depyh Range (Virato Depth Range)
          // TODO: find out if this is right (if necessary), because spec was missisng in source
          const value = (this.RPN_Data_High << 7 + this.RPN_Data_Low) * 100 / 8192;
          // General_MIDI_Level_2_07-2-6_1.2a.pdf
          this.addControl(time, otherControls.modulationDepth, value);
        } else {
          // TODO: Find out how they map this in midi 2.0
          const controlNr = (this.RPN_High << 8 + this.RPN_Low) | 0x010000;
          const value = (this.RPN_Data_High << 7 + this.RPN_Data_Low) * 100 / 8192;
          this.addControl(time, controlNr, value);
        }
      } else if (this.NRPNActive) {
        console.log('NRPN not implemented, needs specs!', this.NRPN_High, this.NRPN_Low, this.RPN_Data_High, this.RPN_Data_Low)
        // TODO: Find out how they map this in midi 2.0
        const controlNr = (this.NRPN_High << 8 + this.NRPN_Low) | 0x020000;
        const value = (this.RPN_Data_High << 7 + this.RPN_Data_Low) * 100 / 8192;
        this.addControl(time, controlNr, value);
      } else {
        console.error('Unspecified (N)RPN parameter entry!', controlNr, value);
      }
    }
    // Since i've seen midi files that mess with control 6 before setting RPN
    // which leads to false pitchRange I use 2 boolean to close entry setting
    switch (controlNr) {
      case 98: {
        this.NRPN_Low = value;
        this.RPNActive = false;
        this.NRPNActive = true;
        break;
      }
      case 99: {
        this.NRPN_High = value;
        this.RPNActive = false;
        this.NRPNActive = true;
        break;
      }
      case 100: {
        this.RPN_Low = value;
        this.RPNActive = true;
        this.NRPNActive = false;
        break;
      }
      case 101: {
        this.RPN_High = value;
        this.RPNActive = true;
        this.NRPNActive = false;
        break;
      }
      case 6: {
        this.RPN_Data_High = value;
        updateRPNVal()
        break;
      }
      case 38: {
        this.RPN_Data_Low = value;
        updateRPNVal()
        break;
      }
    }
  }

}

// TODO change to static when FF finaly supports statics or was it safari ;)
TrackData.getKey = function(trackNr, channelNr) {
  return trackNr + '_' + channelNr;
}

class ProgramEntry {
  constructor (key, program) {
    this.key = key;
    this.program = program;
  }

  getShaderName() {
    return this.midiName;
  }

  get midiName() {
    return instruments[this.program];
  }

  get midiGroup() {
    return getGroupName(this.program);
  }
}

export {
  TrackData,
  TrackEntry,
  TrackNote,
  TrackControl,
  TrackTempo,
  TrackTimeSignature,
  TrackInstrument,
  ProgramEntry
}
