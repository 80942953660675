// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

import dataModel from './data/dataModel.js';
import { MusicKeyboard } from '../KMN-gl-synth-browser/components/webgl/music-keyboard.js';
import { otherControls } from '../KMN-gl-synth.js/otherControls.js';
import { MusicInterface } from '../KMN-gl-synth-browser/interfaces/music-interface.js';
import { addCSS } from '../KMN-varstack-browser/utils/html-utils.js';

const cssStr = /*css*/`
`;

const defaultOptions = {
  startKey: 0,
  endKey: 120
};

const keyboardNotes1 = 'q2w3er5t6y7u' + 'i9o0p[=]';
const keyboardNotes2 = 'zsxdcvgbhnjm' + ',l.;/';

class SoftwareKeyBoard {
  constructor(options) {
    this.options = { ...defaultOptions, ...options };
    this.octaveHeaderHeight = 16;
    /** @type {MusicInterface} */
    this._music = null;
  }

  /** @type {MusicInterface} */
  set music (value) {
    this._music = value;
    if (this.webglKeyboard) {
      this.webglKeyboard.music = this._music;
    }
  }

  get music() {
    return this._music;
  }

  /**
   * @param {HTMLElement} parentElement
   */
  initializeDOM(parentElement) {
    this.parentElement = parentElement;

    this.webglKeyboard = new MusicKeyboard(this.parentElement)
    this.webglKeyboard.music = this._music;
    dataModel.musicKeyboard = this.webglKeyboard;

    // addCSS('software-keyboard', cssStr);

    var noteList = []
    const charToNote = c => {
      let ix = keyboardNotes1.indexOf(c);
      if (ix !== -1) return ix + 48;
      ix = keyboardNotes2.indexOf(c);
      if (ix !== -1) return ix + 60;
      return 0;
    }
    parentElement.addEventListener('keydown', evt => {
      let c = evt.key.toLowerCase();
      let note = charToNote(c);
      // prevent Repeat by checking if allready set
      if (note !== -1 && !noteList[note]) {
        noteList[note] = this.music.note(Date.now() / 1000.0, 'soft-kbd', 1, note, 1);
      }
    });
    parentElement.addEventListener('keyup', evt => {
      let c = evt.key.toLowerCase();
      let note = charToNote(c);
      if (note !== -1 && noteList[note]) {
        noteList[note].release(Date.now() / 1000.0);
        noteList[note] = undefined;
      }
    });
  }
}

export default SoftwareKeyBoard;

