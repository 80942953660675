// Copyright by André van Kammen
// Licensed under CC BY-NC-SA 
// https://creativecommons.org/licenses/by-nc-sa/4.0/

// TOTO: implement some RPN commands for pitch bend range (that's why it was off in some midis i think)
// From: http://www.philrees.co.uk/nrpnq.htm
// Pitch Bend Sensitivity (0, 0)
//   -(Data Entry MSB) range in semitones
//   -(Data Entry LSB) range in cents
// Fine Tuning (0, 1), 
//   -(MSB+LSB) represents the tuning displacement (up or down) in fractional parts 
//    of a semitone (full range equals one semitone, a single step represents 
//    100/8192 cents); the centre value (8192) corresponds to the standard (A440) tuning. 
// Coarse Tuning (0, 2), 
//   -MSB displacement (up or down) in whole semitones; the centre value (64) corresponds to standard tuning.
// Tuning Program Select (0, 3) no idea
// Tuning Bank Select (0, 4).
// Null (127, 127), basicly close RPN setting so Data Entry is decoupled

import dataModel from '../data/dataModel.js'

import TrackPlayer from './arrangement-player.js';
import { TrackData, ProgramEntry, TrackNote } from './arrangement-data.js';
import TrackTimeline from './arrangement-timeline.js';

import parseMidiFile from '../ext/jasmid.js'
import { otherControls } from '../../KMN-gl-synth.js/otherControls.js';
import { addCSS, kmnClassName } from '../../KMN-varstack-browser/utils/html-utils.js';
import { HorizontalSliderElement } from '../../KMN-varstack-browser/components/webgl/sliders.js';
import { MusicInterface } from '../../KMN-gl-synth-browser/interfaces/music-interface.js';
import { PlayPauseElement } from '../../KMN-varstack-browser/components/webgl/play-pause.js';

const cssStr = /*css*/`
.${kmnClassName} {
  --tracksHeaderHeight: 40px;
  --trackSelectWidth: 320px;
  --trackBackground: rgb(24, 24, 24);
  --trackText: rgb(200,200,200);
}
.${kmnClassName}.trackSelectHeader {
  background: var(--subHeaderBackground);
  border-bottom: var(--subBorderWidth) solid var(--borderColor);
  border-right: var(--subBorderWidth) solid var(--borderColor);
  color: var(--subHeaderColor);
  width: calc(var(--trackSelectWidth) - var(--subBorderWidth));
  height: calc(var(--tracksHeaderHeight) - var(--subBorderWidth));
  padding: 2px 12px;
  line-height: calc(var(--codeHeaderHeight) - var(--subBorderWidth) - 6px);
}
.${kmnClassName}.trackPlay {
  position: absolute;
  top: 0;
  left: 0; 
  width: 120px;
  height: calc(var(--tracksHeaderHeight) - var(--subBorderWidth));
  color: var(--subHeaderColor);
  background: var(--activeColor);
  outline: none;
  border: none;
  padding: 3px 12px;
  text-align: center;
  font: inherit;
}
.${kmnClassName}.trackPlay:hover {
  background: var(--activeHoverColor);
  color: white;
}
.${kmnClassName}.trackSelect {
  background: var(--backgroundColor);
  color: var(--subHeaderColor);
  border-right: var(--subBorderWidth) solid var(--borderColor);
  width: calc(var(--trackSelectWidth) - var(--subBorderWidth));
  overflow-x: hidden;
  overflow-y: auto;
  top: var(--tracksHeaderHeight);
  height: calc(100% - var(--tracksHeaderHeight));
}
.${kmnClassName}.trackTitle {
  position: relative;
  padding: 2px 12px;
  height: 32px;
  width: 100%;
  outline: 1px solid black;
  line-height: 27px;
  overflow: hidden;
}
.${kmnClassName}.trackTimeline {
  background: var(--subHeaderBackground);
  border-bottom: var(--subBorderWidth) solid var(--borderColor);
  height: calc(var(--tracksHeaderHeight) - var(--subBorderWidth));
  color: var(--subHeaderColor);
  left: var(--trackSelectWidth);
  width: calc(100% - var(--trackSelectWidth));
}
.${kmnClassName}.trackContent {
  background: var(--trackBackground);
  left: var(--trackSelectWidth);
  top: var(--tracksHeaderHeight);
  height: calc(100% - var(--tracksHeaderHeight));
  width: calc(100% - var(--trackSelectWidth));
  overflow: auto;
}
`

const defaultOptions = {
  maxNoteLength: 30,
  defaultNoteLength: 30.1
};

class TrackEditor {
  constructor(options) {
    this.options = { ...defaultOptions, ...options };

    this.clearTracks();
    this.trackPlayer = new TrackPlayer(this)
    this.trackTimeline = new TrackTimeline(this, this.options)

    /** @type {MusicInterface} */
    this.music = null;

    this.ticksPerBeat = 96;
  }

  loadMidi(url) {
    this.clearTracks();
    fetch(url)
      .then(response => response.blob())
      .then(data => {
        // console.log(data);
        this.importMidi(data);
      });
  }

  // updatePlayButton() {
  //   this.trackPlayButton?.$setTextNode(
  //     this.trackPlayer.isPlaying()
  //     ? 'STOP' : 'PLAY'
  //   );
  // }

  playTracks() {
    if (!this.trackPlayer.isPlaying()) {
      // Preload the instruments
      this.music.clear();
      this.music.preLoadPrograms(this.usedInstruments);
      
      this.trackPlayer.play();
    } else {
      this.trackPlayer.stop();
    }
    // this.updatePlayButton();
  }

  /** @returns {TrackData} */
  getTrack(trackNr, channelNr) {
    let key = TrackData.getKey(trackNr, channelNr);
    let trackData = this.tracks[key];
    if (!trackData) {
      trackData = new TrackData(this, trackNr, channelNr);
      this.tracks[key] = trackData;
    }
    return trackData;
  }

  getInstrumentsUsed() {
    return this.usedInstruments;
  }

  getPercussionUsed() {
    return this.usedPercussion;
  }

  addTrackInstrument (program) {
    let key = 'midi_' + program;
    let instrument = this.midiMap[key]
    if (!instrument) {
      instrument = new ProgramEntry(key, program);
      this.midiMap[key] = instrument;
    }
    return instrument;
  }

  addMidiInstrument (program) {
    if (this.usedInstruments.indexOf(program) === -1) {
      this.usedInstruments.push(program);
    }
    return this.addTrackInstrument(program);
  }

  addPercussionUsed (note) {
    note = ~~ note;
    if (this.usedPercussion.indexOf(note) === -1) {
      this.usedPercussion.push(note);
    }
  }

  clearTracks () {
    // TODO UI cleanup
    this.tracks = {};
    this.midiMap = {};
    this.usedInstruments = [];
    this.usedPercussion = [];

    this.trackPlayer?.stop();
  }

  importMidi(fileBlob) {
    let fileReader = new FileReader();
    let track = this.getTrack(0, 0); // All timing in track 0
    fileReader.onload = evt2 => {
      let midiFile = parseMidiFile(evt2.target.result);
      let pitchBendCount = 0;
      let controllers = {}
      let unhandledEntries = []
      for (let trackIx = 0; trackIx < midiFile.tracks.length; trackIx++) {
        let time = 0;
        /** @type {TrackNote[]} */
        let trackNotes = [];
        for (let msg of midiFile.tracks[trackIx]) {
          time += msg.deltaTime;
          // const realTime = time / midiFile.header.ticksPerBeat;
          if (msg.type === 'midi') {
            if (msg.subType === 'noteOn') {
              if (msg.channel === 9) {
                this.addPercussionUsed(msg.note)
              }
              let track = this.getTrack(trackIx, msg.channel);
              // Close the same previous note in this track, it happens in some midi-files
              // without velocity 0? or was that because i forgot channel in the key?
              let noteKey = msg.channel+'_'+msg.note;
              if (trackNotes[noteKey]) {
                trackNotes[noteKey].updateDuration(
                  time - trackNotes[noteKey].startTime,
                  msg.velocity / 127 // ?? Use the new note's velocity as the release velocity ??
                );
              }
              trackNotes[noteKey] = track.addNote(
                time,
                msg.note,
                NaN,// this.options.defaultNoteLength * this.ticksPerBeat,
                msg.velocity / 127
              );
            } else if (msg.subType === 'noteOff') {
              let noteKey = msg.channel+'_'+msg.note;
              if (trackNotes[noteKey]) {
                trackNotes[noteKey].updateDuration(
                  time - trackNotes[noteKey].startTime,
                  msg.velocity / 127
                );
                trackNotes[noteKey] = null;
              } else {
                // console.error('Double note off or note not on');
              }
            } else if (msg.subType === 'pitchBend') {
              let track = this.getTrack(trackIx, msg.channel);
              let bendValue = (msg.value / 16383) * 2.0 - 1.0;
              pitchBendCount++;
              track.addControl(time, otherControls.pitch, bendValue);
            } else if (msg.subType === 'controller') {
              let track = this.getTrack(trackIx, msg.channel);
              controllers[msg.controllerType] = ~~controllers[msg.controllerType] + 1
              track.addControl(time, msg.controllerType, msg.value / 127);
              if ([6,38,98,99,100,101].indexOf(msg.controllerType)!==-1) {
                track.setRPN(time, msg.controllerType, msg.value);
              }
            } else if (msg.subType === 'programChange') {
              let track = this.getTrack(trackIx, msg.channel);
              let instrument = this.addMidiInstrument(msg.program);
              track.addInstrument(time, instrument);
            } else {
              unhandledEntries.push(msg);
            }
          } else if (msg.type === 'meta') {
            if (msg.subType === 'sequencerSpecific') {
              // Can't handle this?
              unhandledEntries.push(msg);
            } else if (msg.subType === 'endOfTrack') {
              // Yeah so what i don't care (yet) :)
            } else if (msg.subType === 'timeSignature') {
              let track = this.getTrack(0, 0); // All timing in track 0
              track.addTimeSignature(
                time,
                msg.numerator,
                msg.denominator,
                msg.metronome,
                msg.thirtyTwoSeconds
              );
            } else if (msg.subType === 'setTempo') {
              // console.log('Tempo: ',time,'-',msg.microsecondsPerBeat);
              let track = this.getTrack(0,0); // All timing in track 0
              track.addTempo(time, msg.microsecondsPerBeat);
            } else if (msg.subType === 'trackName') {
              let keys = Object.keys(this.tracks);
              for (let ix = 0; ix < keys.length; ix++) {
                const key = keys[ix];
                if (key.startsWith(trackIx + '_')) {
                  const track = this.tracks[key];
                  track.setName(msg.text);
                }
              }
            } else
              unhandledEntries.push(msg);
          } else unhandledEntries.push(msg);
        }
      }
      console.log('File:                 ', midiFile);
      console.log('Unimplemented entries ', unhandledEntries)
      console.log('Instruments           ', this.midiMap);
      console.log('Pitchbends            ', pitchBendCount);
      console.log('Controllers           ', controllers);
      this.ticksPerBeat = midiFile.header.ticksPerBeat;
      this.updateInterface();
    };
    fileReader.readAsArrayBuffer(fileBlob);
  }

  updateInterface() {    
    this.trackTimeline.loadTrackData(this.tracks);
    this.trackPlayer.prepareTracks(this.tracks, this.ticksPerBeat);

    // Wait till we have data and a UI
    this.trackSelectElement.$removeChildren()
    let keys = Object.keys(this.tracks);
    if (keys.length && this.parentElement) {
      for (let ix = 0; ix < keys.length; ix++) {
        const key = keys[ix];
        const track = this.tracks[key];
        let el = this.trackSelectElement.$el({ cls: 'trackTitle' });
        el.innerText =
          (track.name || track.trackNr + ': ') +
          ('(' + track.channelNr) + ')\t' + 
          (track.instrument?(track.instrument.midiName || track.instrument.groupName):'') +
          '('  + track.entries.length + ')';
      }
    }
    this.trackContent.scrollTop = 90;
  }

  /**
   * @param {HTMLElement} parentElement
   * @param {HTMLCanvasElement} canvas
   */
  initializeDOM(parentElement, canvas) {
    this.parentElement = parentElement;
    addCSS('track-editor', cssStr);
    this.trackSelectHeaderElement = this.parentElement.$el({
      cls: 'trackSelectHeader'
    });
    this.trackSelectElement = this.parentElement.$el({ cls: 'trackSelect' });
    this.trackTimeLineElement = this.parentElement.$el({ cls: 'trackTimeline' });
    this.trackContent = this.parentElement.$el({ cls: 'trackContent' });

    this.trackTimeline.initializeDOM(this.trackContent, canvas);

    // this.trackPlayButton = this.trackSelectHeaderElement.$el({
    //   tag: 'button',
    //   cls: 'trackPlay'
    // });
    // this.trackPlayButton.$setTextNode('PLAY');
    // this.trackPlayButton.onclick = (evt) => this.playTracks();
    this.playPauseButton = new PlayPauseElement(
      this.trackPlayer.playingVar,
      this.trackSelectHeaderElement);
    
    // TODO implement, only copied here for editing
    // new PlayNextElement(new ActionVar(), this.trackSelectHeaderElement).dispose();
    // new PlayPreviousElement(new ActionVar(), this.trackSelectHeaderElement).dispose();
    // new PlayForwardElement(new ActionVar(), this.trackSelectHeaderElement).dispose();
    // new PlayReverseElement(new ActionVar(), this.trackSelectHeaderElement).dispose();

    new HorizontalSliderElement(
      this.trackPlayer.playPosition,
      this.trackTimeLineElement
      // this.trackPlayer.tempoAndTime.microsecondsPerBeat
    );


    this.updateInterface();
  }
}
export default TrackEditor;
